<template>
  <section>
    <validation-observer ref="eventsForm" #default="{ invalid }">
      <b-form ref="form" @submit.prevent="onCreateOrUpdate">
        <b-card
          class="blog-edit-wrapper"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Title"
                label-for="title"
                class="mb-2"
              >
                <template #label>
                  <span>Title<sup style="color: tomato">*</sup></span>
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="event_data.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Short Description"
                label-for="excerpt"
                class="mb-2"
              >
                <template #label>
                  <span>Short Description<sup style="color: tomato">*</sup> <small>chars: {{ event_data.excerpt.length }}</small></span>
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="Short Description"
                  rules="required|max:150"
                >
                  <b-form-textarea
                    id="excerpt"
                    v-model="event_data.excerpt"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col cols="12">
              <b-form-group
                label="Content"
                label-for="content"
                class="mb-2"
              >
              <template #label>
                <span>Content<sup style="color: tomato">*</sup></span>
              </template>
              
              <validation-provider
                #default="{ errors }"
                name="Content"
              >
                <quill-editor
                  id="content"
                  v-model="event_data.content"
                  :options="snowOption"
                  :state="errors.length > 0 ? false : null"
                />
                <!-- <small class="text-danger">{{ !event_data.content && event_data.title? 'Please enter event content' : '' }}</small> -->
              </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Contacts"
                label-for="contacts"
                class="mb-2"
              >
                <template #label>
                  <span>Contacts<sup style="color: tomato">*</sup></span>
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="Contacts"
                >
                  <b-form-input
                    id="contacts"
                    v-model="event_data.contacts"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="6">
                  <b-form-group
                    placeholder="Start Date"
                    label="Start Date"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Start Date<sup style="color: tomato">*</sup></span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Start Date"
                      :rules="{
                        required: true,
                      }"
                    >
                      <b-form-datepicker
                        id="event_start_date"
                        v-model="event_data.event_start_date"
                        :min="new Date()"
                        :state="errors.length > 0 ? false : null"
                        value-as-date
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    placeholder="End Date"
                    label="End Date"
                    class="mb-2"
                  >
                    <template #label>
                      <span>End Date<sup style="color: tomato">*</sup></span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="End Date"
                      :rules="{
                        required: true,
                      }"
                    >
                      <b-form-datepicker
                        id="event_end_date"
                        v-model="event_data.event_end_date"
                        :min="event_data.event_start_date"
                        :state="errors.length > 0 ? false : null"
                        value-as-date
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="6">
                  <b-form-group
                    placeholder="Start Time"
                    label="Start Time"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Start Time<sup style="color: tomato">*</sup></span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Start Time"
                      rules="required"
                    >
                      <b-form-timepicker
                        id="event_start_time"
                        v-model="event_data.event_start_time"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    placeholder="End Time"
                    label="End Time"
                    class="mb-2"
                  >
                    <template #label>
                      <span>End Time<sup style="color: tomato">*</sup></span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="End Time"
                      rules="required"
                    >
                      <b-form-timepicker
                        id="event_end_time"
                        v-model="event_data.event_end_time"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Venue / Location"
                    label-for="event_venue"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Venue / Location<sup style="color: tomato">*</sup></span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Venue / Location"
                      rules="required"
                    >
                      <b-form-input
                        id="event_venue"
                        v-model="event_data.event_venue"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Event Link"
                    label-for="event_link"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Event Link"
                      rules=""
                    >
                      <b-form-input
                        id="event_link"
                        v-model="event_data.event_link"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Event Type"
                    label-for="event_type"
                    class="mb-2"
                  >
                    <v-select
                      id="event_type"
                      v-model="event_data.event_type"
                      :clearable="false"
                      :options="event_types"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Require Accommodation?"
                    label-for="require_accommodation"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Require Accommodation?"
                      rules=""
                    >
                      <b-form-checkbox
                        id="require_accommodation"
                        v-model="event_data.require_accommodation"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col v-if="event_data.require_accommodation" md="6">
                  <b-form-group
                    label="Select Accommodation(s)"
                    label-for="accommodations-select"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Select Accommodation(s)<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      id="accommodations-select"
                      v-model="event_data.accommodations"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="accommodations"
                      :reduce="val => val.value"
                      :clearable="true"
                      :close-on-select="false"
                      multiple
                      @option:deselected="onAccommodationDeselected"
                      @option:selected="accommodationSelectionChangedHandler"
                    />
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Require Canteen?"
                    label-for="require_canteens"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Require Canteen?"
                      rules=""
                    >
                      <b-form-checkbox
                        id="require_canteens"
                        v-model="event_data.require_canteen"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col v-if="event_data.require_canteen" md="6">
                  <b-form-group
                    label-for="canteens-select"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Select Canteen(s)<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      id="canteens-select"
                      v-model="event_data.canteens"
                      :clearable="true"
                      :options="canteens"
                      :close-on-select="false"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                    />
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Require Product?"
                    label-for="require_products"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Require Product?"
                      rules=""
                    >
                      <b-form-checkbox
                        id="require_products"
                        v-model="event_data.require_product"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col v-if="event_data.require_product" md="6">
                  <b-form-group
                    label-for="products-select"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Select Product(s)<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      id="products-select"
                      v-model="event_data.products"
                      :clearable="true"
                      :options="products"
                      :close-on-select="false"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                    />
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col md="12">
             <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Require Speakers?"
                    label-for="require_speakers"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Require Speakers?"
                      rules=""
                    >
                      <b-form-checkbox
                        id="require_speakers"
                        v-model="event_data.require_speakers"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col v-if="event_data.require_speakers" md="6">
                  <b-form-group
                    label="Select Speakers(s)"
                    label-for="accommodations-select"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Select Main Speaker<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      id="speakers-select"
                      v-model="event_data.main_speaker"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="mainSpeakers"
                      :reduce="val => val.value"
                      :close-on-select="false"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Select Speakers(s)"
                    label-for="accommodations-select"
                    class="mb-2"
                  >
                    <template #label>
                      <span>Select Speaker(s)<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      id="speakers-select"
                      v-model="event_data.speakers"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="speakerOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      :close-on-select="false"
                      multiple
                      @option:deselected="onSpeakerDeselected"
                      @option:selected="speakerSelectionChangedHandler"
                    />
                  </b-form-group>
                </b-col>
             </b-row>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <b-row class="match-height">
                <b-col cols="6">
                  <div class="border rounded p-2">
                    <label class="mb-1">Thumbnail Image</label>
                    <b-media
                      no-body
                      vertical-align="center"
                      class="flex-column flex-md-row"
                    >
                      <b-media-aside v-if="thumbnailImagePath">
                        <b-img
                          :src="thumbnailImagePath"
                          height="110"
                          width="170"
                          class="rounded mr-2 mb-1 mb-md-0"
                        />
                      </b-media-aside>
                      <b-media-body>
                        <small class="text-muted">Required image resolution 370X370, image max size 5mb.</small>
                        <b-card-text class="my-50">
                        </b-card-text>
                        <div class="d-inline-block w-100">
                          <validation-provider
                            #default="{ errors }"
                            name="Thumbnail Image"
                            :rules="{
                              required: !isUpdate,
                              size: 5000,
                              dimensions: { width: 370, height: 370 }
                            }"
                          >
                            <b-form-group
                              label=""
                              label-for="thumbnail-image-file"
                            >
                              <b-form-file
                                id="thumbnail-image-file"
                                v-model="thumbnailImage"
                                accept=".jpg, .png, .jpeg, .webp"
                                placeholder="Choose file"
                                :state="errors.length > 0 ? false : null"
                                @input="onThumbnailRenderer"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </b-media-body>
                    </b-media>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div class="border rounded p-2">
                    <label class="mb-1">Flyer Image<sup style="color: tomato">*</sup></label>
                    <b-media
                      no-body
                      vertical-align="center"
                      class="flex-column flex-md-row"
                    >
                      <b-media-aside v-if="flyerImagePath">
                        <b-img
                          :src="flyerImagePath"
                          height="110"
                          width="170"
                          class="rounded mr-2 mb-1 mb-md-0"
                        />
                      </b-media-aside>
                      <b-media-body>
                        <small class="text-muted">Required image resolution 1024X500, image max size 5mb.</small>
                        <b-card-text class="my-50">
                        </b-card-text>
                        <div class="d-inline-block w-100">
                          <validation-provider
                            #default="{ errors }"
                            name="Flyer Image"
                            :rules="{
                              required: !isUpdate,
                              size: 5000,
                              dimensions: { width: 1024, height: 500 }
                            }"
                          >
                            <b-form-group
                              label=""
                              label-for="flyer-image-file"
                            >
                              <b-form-file
                                id="flyer-image-file"
                                v-model="flyerImage"
                                accept=".jpg, .png, .jpeg, .webp"
                                placeholder="Choose file"
                                :state="errors.length > 0 ? false : null"
                                @input="onFlyerRenderer"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </b-media-body>
                    </b-media>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                class="mb-5"
                style="width: 150px"
                variant="primary"
                block
                type="submit"
                :disabled="invalid || disableForm"
              >
                {{ isUpdate ? 'Update Event' : 'Create Event' }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import {
  BCard, 
  BMedia, 
  BAvatar, 
  BCardText, 
  BMediaAside, 
  BMediaBody, 
  BForm, 
  BRow, 
  BCol, 
  BImg, 
  BLink, 
  BButton,
  BFormFile, 
  BFormGroup, 
  BFormInput, 
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { required } from "@validations";
import { cloneDeep, get } from "lodash";
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { addDays, isAfter } from 'date-fns/esm';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


const raw_event_data = {
  title: "",
  contacts: "",
  excerpt: "",
  main_speaker: "",
  speakers: [],
  content: "",
  event_link: "",
  event_type: "None",
  event_venue: "",
  event_end_date: '',
  event_start_date: '',
  event_start_time: '',
  event_end_time: '',
  accommodations: [],
  canteens: [],
  products: [],
  require_accommodation: false,
  require_canteen: false,
  require_product: false,
  require_speakers: false
}
export default {
  components: {
    BCard,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BButton,
    vSelect,
    BCardText,
    BFormFile,
    BMediaBody,
    BFormGroup,
    BFormInput,
    BMediaAside,
    quillEditor,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    updatingEvent: {
      type: [Object, null, undefined],
      default: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      
      flyerImage: null,
      flyerImagePath: "",

      thumbnailImage: null,
      thumbnailImagePath: "",

      accommodations: [],
      speakers: [],
      canteens: [],
      products: [],
      event_types: [
        {
          label: 'N/A',
          value: 'None'
        },
        {
          label: 'Conference',
          value: 'Conference'
        },
        {
          label: 'Convention',
          value: 'Convention'
        },
        {
          label: 'Seminar',
          value: 'Seminar'
        },
        {
          label: 'Exhibition',
          value: 'Exhibition'
        },
        {
          label: 'Festival',
          value: 'Festival'
        },
        {
          label: 'Meeting',
          value: 'Meeting'
        },
        {
          label: 'Wedding',
          value: 'Wedding'
        },
        {
          label: 'Hybrid',
          value: 'Hybrid'
        }
      ],
      event_data: cloneDeep(raw_event_data),

      snowOption: {
        theme: 'snow',
      },

      required
    }
  },
  computed: {
    isUpdate(){
      return !!this.updatingEvent
    },
    disableForm(){
      return this.isUpdate ? !this.can(["events:CanUpdate"]) : !this.can(["events:CanAddNew"])
    },
    mainSpeakers() {
      return this.speakers.filter(s => !this.event_data.speakers.includes(s.value))
    },
    speakerOptions() {
      return this.speakers.filter(s => !this.event_data.main_speaker !== s.value)
    }
  },
  watch: {
    updatingEvent: {
      handler(update){
        if (update){
          this.event_data = {
            title: update.title,
            excerpt: update.excerpt,
            content: update.content,
            contacts: update.contacts,
            event_link: update.event_link,
            event_type: update.event_type,
            event_venue: update.event_venue,
            accommodations: update.accommodations,
            main_speaker: update.main_speaker || "",
            require_speakers: update.require_speakers,
            speakers: update.speakers,
            canteens: update.canteens,
            products: update.products,
            event_end_date: new Date(update.event_end_date),
            event_start_date: new Date(update.event_start_date),
            event_end_time: update.event_end_time,
            event_start_time: update.event_start_time,
            require_accommodation: update.require_accommodation,
            require_canteen: update.require_canteen,
            require_product: update.require_product,
          }

          const hasFlyerImage = get(update, 'flyer_image.path');
          if (hasFlyerImage){
            this.flyerImagePath = hasFlyerImage
          }

          const hasThumbnailImage = get(update, 'thumbnail_image.path');
          if (hasThumbnailImage){
            this.thumbnailImagePath = hasThumbnailImage
          }
        }
      }, 
      deep: true, 
      immediate: true
    },
    'event_data.event_start_date': {
      handler(event_start_date){
        const { event_end_date } = this.event_data;
        if (event_end_date){
          if (isAfter(new Date(event_start_date), new Date(event_end_date))){
            this.event_data.event_end_date = addDays(new Date(event_start_date), 1)
          }
        }
      }
    }
  },
  created() {
    this.fetchAccommodations();
    this.fetchCanteens();
    this.fetchProducts();
    this.fetchSpeakers();
  },
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        
        const success = await this.$refs.eventsForm.validate();
        if (!success){
          return;
        }

        const formData = new FormData();

        Object.keys(this.event_data).forEach(key => {
          const value = this.event_data[key]
          if (Array.isArray(value)){
            value.forEach(val => {
              formData.append(key, val);
            })
          } else {
            value !== "" && formData.append(key, value);
          }
        })

        if (this.flyerImage){
          formData.append('flyer_image', this.flyerImage)
        }

        if (this.thumbnailImage){
          formData.append('thumbnail_image', this.thumbnailImage)
        }

        if (this.isUpdate){
          this.$emit("update", formData)
        } else {
          this.$emit("create", formData)
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchAccommodations() {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
        }

        const request = await this.useJwt().adminService.fetchEventCenterAccommodations(query);
        const { data } = request.data;

        this.accommodations = data.map(acc => ({ label: acc.title, value: acc._id, ...acc }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchSpeakers() {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
        }

        const request = await this.useJwt().adminService.fetchSpeakers(query);
        const { data } = request.data;

        this.speakers = data.map(acc => ({ label: acc.name, value: acc._id, ...acc }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchCanteens() {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
        }

        const request = await this.useJwt().adminService.fetchCanteens(query);
        const { data } = request.data;

        this.canteens = data.map(acc => ({ label: acc.title, value: acc._id, ...acc }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
        }

        const request = await this.useJwt().adminService.fetchProducts(query);
        const { data } = request.data;

        this.products = data.map(acc => ({ label: acc.name, value: acc._id, ...acc }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onAccommodationDeselected(deselectedOption){
      try {
        if (!this.isUpdate){
          this.event_data.accommodations = this.event_data.accommodations.filter(opt => opt !== deselectedOption._id)
          return;
        }

        const result = await this.$swal({
          title: 'Remove Accommodation?',
          text: "Do you want to remove this accommodation?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          this.event_data.accommodations.push(deselectedOption._id);
          return;
        }
        
        if (this.event_data.accommodations.length < 1) {
          this.event_data.accommodations.push(deselectedOption._id);
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
              text: "Sorry. Events with accommodation need at least one accommodation selected."
            },
          });
        }

        const event_id = this.getValueFromSource(this.updatingEvent, '_id');
        const { _id: accommodation_id } = deselectedOption;

        await this.useJwt().adminService.deleteEventAccommodation(event_id, accommodation_id)
        this.event_data.accommodations = this.event_data.accommodations.filter(opt => opt !== accommodation_id);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: "Accommodation removed successfully"
          },
        });
      } catch (error){
        this.event_data.accommodations.push(deselectedOption._id);
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onSpeakerDeselected(deselectedOption){
      try {
        if (!this.isUpdate){
          this.event_data.speakers = this.event_data.speakers.filter(opt => opt !== deselectedOption._id)
          return;
        }

        const result = await this.$swal({
          title: 'Remove Speaker?',
          text: "Do you want to remove this speaker?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          this.event_data.speakers.push(deselectedOption._id);
          return;
        }
        
        if (this.event_data.speakers.length < 1) {
          this.event_data.speakers.push(deselectedOption._id);
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
              text: "Sorry. Events with speaker need at least one speaker selected."
            },
          });
        }

        const event_id = this.getValueFromSource(this.updatingEvent, '_id');
        const { _id: speaker_id } = deselectedOption;

        await this.useJwt().adminService.deleteEventSpeaker(event_id, speaker_id)
        this.event_data.speakers = this.event_data.speakers.filter(opt => opt !== speaker_id);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: "Speaker removed successfully"
          },
        });
      } catch (error){
        this.event_data.speakers.push(deselectedOption._id);
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    accommodationSelectionChangedHandler(selectedAccommodations) {
      this.event_data.accommodations = selectedAccommodations.map(opt => opt._id)
    },
    speakerSelectionChangedHandler(selectedSpeekers) {
      this.event_data.speakers = selectedSpeekers.map(opt => opt._id)
    },
    onFlyerRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.flyerImagePath = reader.result
          this.flyerImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    onThumbnailRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.thumbnailImagePath = reader.result
          this.thumbnailImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    resetForm(){
      Object.keys(cloneDeep(this.event_data)).forEach(key => {
        const resetValue = get(raw_event_data, key);
        this.event_data[key] = resetValue
        this.$set(this.event_data, resetValue);
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
