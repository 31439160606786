<template>
  <div>
    <add-or-update-event-activity-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      :update-data="updateData"
      :add-activity="addActivity"
      :update-activity="updateActivity"
      @resetUpdateData="resetUpdateData"
    />

    <div v-if="can('events:CanAddActivity')" class="d-flex justify-content-end">
      <b-button variant="outline-primary" @click="isAddOrUpdateActive = true">
        <feather-icon icon="PlusIcon" />
        Add Activity
      </b-button>
    </div>

    <list-table
      :records="eventActivities"
      :columns="tableColumns"
      :current-page-number="1"
      :total-records="eventActivities.length"
      :show-search="false"
      :show-export-button="false"
      :show-filter-by-default="false"
    >

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ getUserStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item v-if="can('events:CanUpdateActivity')" @click="onEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

           <b-dropdown-item v-if="can('events:CanDeleteActivity')" @click="onDelete(data.item._id)">
            <feather-icon icon="DeleteIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ListTable from "@core/components/ListTable/ListTable.vue";
import AddOrUpdateEventActivityModal from "@core/components/shared/admin/events/AddOrUpdateEventActivityModal.vue";

import { BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem, } from "bootstrap-vue";

export default {
  name: "ListEventCenters",
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    ListTable,
    BDropdown,
    BDropdownItem,
    AddOrUpdateEventActivityModal
  },
  directives: {
    Ripple,
  },
  props: {
    eventActivities: {
      required: true,
      type: Array
    },
    addActivity: {
      required: true,
      type: Function
    },
    updateActivity: {
      required: true,
      type: Function
    },
    removeActivity: {
      required: true,
      type: Function
    },
  },
  data() {
    return {
      loading: false,

      updateData: null,
      isAddOrUpdateActive: false,

      tableColumns: [
        { key: 'title', sortable: false },
        { key: 'start_time', formatter: val => `${this.formatTime(val)}`, sortable: false },
        { key: 'end_time', formatter: val => `${this.formatTime(val)}`, sortable: false },
        { key: 'time_note', sortable: false },
        { key: 'description', sortable: false },
        { key: 'status', sortable: false },
        { key: 'actions' },
      ]
    }
  },
  methods: {
    onDelete(id){
      this.removeActivity(id)
    },
    onEdit(update){
      this.updateData = update;
      this.isAddOrUpdateActive = true;
    },
    resetUpdateData(){
      if (this.updateData){
        this.updateData = null
      }
    },
  }
}
</script>

<style scoped>

</style>
