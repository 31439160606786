<template>
  <div class="blog-detail-wrapper">
    <b-row>
      <b-col cols="12">
        <b-card
          :img-src="getValueFromSource(eventData, 'thumbnail_image.path')"
          :title="getValueFromSource(eventData, 'title')"
          img-top
          img-alt="Event Thumbnail"
        >
          <b-media no-body>
            <b-media-body>
              <small class="text-muted">{{ formatDate(getValueFromSource(eventData, 'event_start_date'), 'MMM dd, yyyy') }} @ {{ formatTime(getValueFromSource(eventData, 'event_start_time')) }}-{{ formatTime(getValueFromSource(eventData, 'event_end_time')) }}</small>
              <span class="text-muted ml-75 mr-50">|</span>
              <small class="text-capitalize text-muted">{{ getValueFromSource(eventData, 'event_venue').toUpperCase() }}</small>
              <span v-if="getValueFromSource(eventData, 'event_link')" class="text-muted ml-75 mr-50">|</span>
              <a v-if="getValueFromSource(eventData, 'event_link')" :href="getValueFromSource(eventData, 'event_link')">{{ getValueFromSource(eventData, 'event_link') }}</a>
            </b-media-body>
          </b-media>
          <div class="my-1 py-25">
            <b-badge
              pill
              class="mr-75"
              :variant="getValueFromSource(eventData, 'status') === 'draft' ? 'light-primary' : 'light-success'"
            >
              {{ capitalizeFirstWord(getValueFromSource(eventData, 'status')) }}
            </b-badge>
          </div>

          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content"
            v-html="getValueFromSource(eventData, 'content')"
          ></div>
          
          <!-- <hr class="my-2"> -->
          <h3>Details</h3>
          <hr class="font-weight-bold" />

          <b-row>
            <b-col md="2">
              <p class="text-uppercase">
                BEGIN</p>
            </b-col>
             <b-col md="10">
              <p class="text-uppercase">
                {{ formatDate(getValueFromSource(eventData, 'event_start_date'), 'MMMM dd, yyyy') }} @ {{ formatTime(getValueFromSource(eventData, 'event_start_time')) }}
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="2">
              <p class="text-uppercase">
                END</p>
            </b-col>
             <b-col md="10">
              <p class="text-uppercase">
                {{ formatDate(getValueFromSource(eventData, 'event_end_date'), 'MMMM dd, yyyy') }} @ {{ formatTime(getValueFromSource(eventData, 'event_end_time')) }}
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="2">
              <p class="text-uppercase">
                LOCATION</p>
            </b-col>
             <b-col md="10">
              <p class="text-uppercase">
                {{ getValueFromSource(eventData, 'event_venue').toUpperCase() }}
              </p>
            </b-col>
          </b-row>

          <b-row v-if="getValueFromSource(eventData, 'event_link')">
            <b-col md="2">
              <p class="text-uppercase">
                LINK</p>
            </b-col>
             <b-col md="10">
              <p class="text-uppercase">
                <a :href="getValueFromSource(eventData, 'event_link')">{{ getValueFromSource(eventData, 'event_link') }}</a>
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col md="6">
              <h3>Event Center Stats</h3>
              <hr class="font-weight-bold" />
              <b-row v-for="(event_stat, index) in Object.entries(eventStats.event_center_stats)" :key="index">
                <b-col md="6">
                  <p class="text-uppercase">
                    {{ event_stat[0] }}
                  </p>
                </b-col>
                <b-col md="6">
                  <p class="text-uppercase">
                    {{ event_stat[1] }}
                  </p>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <h3>Event Applicant Sector Stats</h3>
              <hr class="font-weight-bold" />
              <b-row v-for="(event_stat, index) in Object.entries(eventStats.applicants_sector_stats)" :key="index">
                <b-col md="6">
                  <p class="text-uppercase">
                    {{ event_stat[0] }}
                  </p>
                </b-col>
                <b-col md="6">
                  <p class="text-uppercase">
                    {{ event_stat[1] }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    eventData: {
      type: Object,
      default: () => ({})
    },
    eventStats: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      blogComment: ""
    }
  },
  methods: {
    kFormatter
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
.text-capitalize {
  text-transform: uppercase;
}
</style>
