<template>
  <section>
    <component :is="getIcon" :key="iconType"></component>
<!--    <pre>-->
<!--      {{ iconType }}-->
<!--    </pre>-->
<!--    <div v-if="iconType === 'users'">-->
<!--      <AllUsers />-->
<!--    </div>-->
<!--    <div v-if="iconType === 'verified'">-->
<!--      <VerifiedUsersIcon />-->
<!--    </div>-->
<!--    <div v-if="iconType === 'unverified'">-->
<!--      <UnverifiedUsersIcon />-->
<!--    </div>-->
<!--    <div v-if="iconType === 'signups'">-->
<!--      <SignupsIcon />-->
<!--    </div>-->
  </section>
</template>

<script>
import AllUsers from '@/assets/svg/AllUsers.svg';
import SignupsIcon from '@/assets/svg/SignupsIcon.svg';
import VerifiedUsersIcon from '@/assets/svg/VerifiedUsersIcon.svg';
import UnverifiedUsersIcon from '@/assets/svg/UnverifiedUsersIcon.svg';



export default {
  name: "UserIcons",
  components: {
    SignupsIcon,
    AllUsers,
    VerifiedUsersIcon,
    UnverifiedUsersIcon,
  },
  props: {
    iconType: {
      type: String,
      required: true,
      default: null
    }
  },
  computed: {
    getIcon() {
      const iconTypes = {
        users: AllUsers,
        verified: VerifiedUsersIcon,
        unverified: UnverifiedUsersIcon,
        signups: SignupsIcon
      }
      return iconTypes[this.iconType];
    }
  }
}
</script>

<style scoped>

</style>
