<template>
  <b-row>
    <b-col cols="12" md="6">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT  © {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href="https://pensagh.online"
          target="_blank"
        >PENSA Ghana</b-link>
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>
    </b-col>

    <b-col
      v-if="showPagination"
      cols="12"
      md="6"
      class="d-flex align-items-center justify-content-end"
    >
      <b-overlay :show="isFetchingRemoteData" :opacity="0.50" variant="white">
        <div class="d-flex">
          <div class="mr-2">
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              direction="top"
              class="per-page-selector d-inline-block mx-50"
              :clearable="false"
              :options="perPageOptions"
              :calculate-position="withPopper"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
            <label>entries</label>
          </div>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRecords"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'

import { createPopper } from '@popperjs/core';
import { BLink, BCol, BRow, BPagination, BOverlay } from 'bootstrap-vue';

export default {
  components: {
    BLink,
    BCol,
    BRow,
    BOverlay,
    BPagination,

    vSelect,
  },
  data() {
    return {
      placement: "top",
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    isFetchingRemoteData() {
      return this.$store.getters[`navigation/isFetchingRemoteData`];
    },
    totalRecords() {
      return this.$store.getters[`navigation/totalRecords`];
    },
    perPageOptions() {
      return this.$store.getters[`navigation/recordsPerPageOptions`];
    },
    showPagination() {
      const { meta } = this.$route;
      return meta.showPagination || false
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    updatePageLimit(lmt){
      const { query } = this.$route;
      
      query.limit = lmt;
      this.$router.replace({ query: {} })
      .catch((e) => { console.log("error", e) })
      .then(() => {
        this.$router.replace({ query }).catch(() => {})
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
