<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        :records="reservations" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="searchFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="false"
        @export="onExport"
      >

        <template #filters>
          <b-row>
            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select"
                placeholder="Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="statusFilter"
                :options="statusOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => statusFilter = val"
              />
            </b-col>
            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select"
                placeholder="Payment Type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="paymentTypeFilter"
                :options="paymentTypeOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => paymentTypeFilter = val"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.user.avatar.path')"
                :text="sentenceCase(avatarText(`${getValueFromSource(data.item, 'user.full_name')}`))"
                :variant="`light-primary`"
                :to="{ name: 'admin-user-single', params: { id: getValueFromSource(data.item, 'user._id') } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-user-single', params: { id: getValueFromSource(data.item, 'user._id') } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data.item, 'user.full_name')) }}
            </b-link>
            <span class="text-muted">{{ getValueFromSource(data.item, 'user.email') }}</span><br />
            <span class="text-muted">@{{ getValueFromSource(data.item, 'user.phone') }}</span>
          </b-media>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(data.item.status) }}
          </b-badge>
        </template>
      </list-table>
    </div>
    <MakeReservationForNewUserModal :show-event-reservation-modal.sync="showNewReservationModal" @refresh="fetchAllData" />

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput
} from 'bootstrap-vue'
import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MakeReservationForNewUserModal from "@/@core/components/shared/admin/reservations/MakeReservationForNewUserModal.vue"

const watchHandler = {
  handler(){
    this.debouncedFetchUsersData()
  }, 
  immediate: false
}
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BInputGroupAppend, 
    BFormInput,
    MakeReservationForNewUserModal,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    showAddUserButton: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      loading: false,
      showFilter: false,
      statusFilter: null,
      searchFilter: "",
      paymentTypeFilter: "",
      showNewReservationModal: false,

      reservations: [],
      eventOptions: [],
      canteenOptions: [],
      accommodationOptions: [],

      tableColumns: [
        { key: 'user', stickyColumn: true, sortable: false, label: 'User' },
        { key: 'transaction_ref', label: 'Payment Ref', sortable: false },
        { key: 'amount', formatter: val => `${this.formatMoney(val)}`, sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
      ],
      statusOptions: [
        { label: 'Success', value: 'success' },
        { label: 'Failed', value: 'failed' },
        { label: 'Pending', value: 'pending' },
      ],
      paymentTypeOptions: [
        { label: 'Merchandise', value: 'merchandise' },
        { label: 'Reservation', value: 'accommodation' }
      ],
      debouncedFetchUsersData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchUsersData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    paymentTypeFilter: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
  },
  created(){
    this.fetchEvents();
    this.fetchCanteens();
    this.fetchEventCenterAccommodations();
    this.debouncedFetchUsersData = debounce(this.fetchAllData, 500);

    const { 
      status, 
      page = 1,
      search = "", 
      payment_for = "",
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.paymentTypeFilter = payment_for;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
        }

        if (this.paymentTypeFilter) {
          query.payment_for = this.paymentTypeFilter
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchPaymentsForReservations(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.reservations = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchEvents() {
      try {
        this.loading = true; 

        const query = {
          limit: 1000,
          page: 1,
        }

        const request = await this.useJwt().adminService.fetchEvents(query);
        const { data } = request.data;

        this.eventOptions = data.map((opt) => ({ label: opt.title, value: opt._id }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchEventCenterAccommodations() {
      try {
        this.loading = true; 

        const query = {
          limit: 1000,
          page: 1,
        }

        const request = await this.useJwt().adminService.fetchEventCenterAccommodations(query);
        const { data } = request.data;

        this.accommodationOptions = data.map((opt) => ({ label: opt.title, value: opt._id }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchCanteens() {
      try {
        this.loading = true; 

        const query = {
          limit: 1000,
          page: 1,
        }

        const request = await this.useJwt().adminService.fetchCanteens(query);
        const { data } = request.data;

        this.canteenOptions = data.map((opt) => ({ label: opt.title, value: opt._id }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onExport(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          status: this.statusFilter,
          client_group_id: this.client_group_id,
          user_type: this.userTypeFilter
        }

        const response = await this.useJwt().adminService.exportUsers(query);
        FileDownload(response.data, "users-export.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    getTableRowClass(item, type){
      const classes = ["loan-list-tb-row"];

      if (type === "row"){
        const is_ezwich_verified = get(item, 'meta.ezwich_verified', false);
        if (is_ezwich_verified === false){
          classes.push('table-danger')
        }
      } 
      return classes
    },
    makeReservation() {
      this.showNewReservationModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
