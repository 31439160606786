<template>
  <content-with-sidebar
    class="cws-container cws-sidebar-right blog-wrapper"
  >
    <b-overlay :show="loading">
      <b-alert
        variant="danger"
        :show="eventData === undefined && !loading"
      >
        <h4 class="alert-heading">
          Error fetching event details
        </h4>
        <div class="alert-body">
          <b-link
            class="alert-link"
            :to="{ name: 'admin-events'}"
          >
            List Events
          </b-link>
        </div>
      </b-alert>

      <event-details v-if="eventData" :event-data="eventData" :event-stats="eventStats" @refresh="fetchEvent" />
    </b-overlay>

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- recent posts -->
      <b-overlay :show="recentLoading">
        <div class="blog-recent-posts">
          <div class="d-flex mb-2">
            <b-button
              v-if="['draft', 'published'].includes(getValueFromSource(eventData, 'status'))"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="getValueFromSource(eventData, 'status') === 'draft' ? 'primary' : 'warning'"
              class="mr-2"
              :disabled="getValueFromSource(eventData, 'status') === 'draft' ? !can('events:CanPublish') : !can('events:CanUnpublish')"
              @click="publishOrUnpublish()"
            >
              {{ getValueFromSource(eventData, 'status') === 'draft' ? 'Publish' : 'Unpublish' }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :to="{ name: 'admin-update-event', params: { id: getValueFromSource(eventData, '_id') } }"
              :disabled="!canUpdate"
            >
              Edit
            </b-button>

            <b-button
              class="ml-auto"
              variant="danger"
              :disabled="!canDelete"
              @click="onDeleteEvent"
            >
              Delete
            </b-button>
          </div>

          <h6 class="section-label mb-75">
            Upcoming Events
          </h6>
          
          <b-media
            v-for="(recentEvent, index) in recentEvents"
            :key="index"
            :class="index? 'mt-2': ''"
            no-body
          >
            <b-media-aside class="mr-2">
              <b-link :href="`/events/${getValueFromSource(recentEvent, '_id')}/view`">
                <b-img
                  :src="getValueFromSource(recentEvent, 'flyer_image.path')"
                  alt=""
                  width="100"
                  height="70"
                  rounded
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  :href="`/events/${getValueFromSource(recentEvent, '_id')}/view`"
                  class="text-body-heading"
                >
                  {{ getValueFromSource(recentEvent, 'title') }}
                </b-link>
              </h6>
              <span class="text-muted mb-0">
                {{ formatDate(getValueFromSource(recentEvent, 'event_start_date'), 'MMM dd, yy') }}
              </span>
            </b-media-body>
          </b-media>
        </div>
      </b-overlay>
      <!--/ recent posts -->
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BMediaAside, BMediaBody, BImg, BLink,
  BCard, BRow, BCol, BBadge, BCardText, BButton
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { get } from "lodash"

import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventDetails from "@/@core/components/shared/admin/events/EventDetails.vue"

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BCardText,
    ContentWithSidebar,

    EventDetails
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      eventData: {},
      recentEvents: [],
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      eventStats: {
        event_center_stats: {},
        applicants_sector_stats: {}
      },
      loading: false,
      recentLoading: false,
    }
  },
  computed: {
    canUpdate(){
      return this.can("events:CanUpdate")
    },
    canDelete(){
      return this.can("events:CanDelete")
    }
  },
  created() {
    this.fetchEvent();
    this.fetchRecentEvents();
    this.fetchEventStats()
  },
  methods: {
    async fetchEvent() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().adminService.fetchEvent(id);
        const { data } = request.data;

        this.eventData = data;
      } catch (error) {
        this.eventData = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchEventStats() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().adminService.fetchEventStats(id);
        const { data } = request.data;

        this.eventStats = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchRecentEvents() {
      try {
        this.recentLoading = true; 

        const query = {
          page: 1,
          limit: 10,
        }

        const request = await this.useJwt().adminService.fetchEvents(query);
        const { data } = request.data;

        this.recentEvents = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.recentLoading = false;
      }
    },
    async onDeleteEvent(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Delete?',
          text: "Do you want to delete this event?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.deleteEvent(this.getValueFromSource(this.eventData, '_id'));
        await this.$router.push({
          name: "admin-events"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Event deleted successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async publish(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Publish?',
          text: "Do you want to publish this event. It will be available to the public?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.publishEvent(this.getValueFromSource(this.eventData, '_id'));
        await this.fetchEvent()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Event published successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async unPublish(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Unpublish?',
          text: "Do you want to unpublish this event. It won't be available to the public?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.unPublishEvent(this.getValueFromSource(this.eventData, '_id'));
        await this.fetchEvent()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Event unpublished successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    publishOrUnpublish() {
      if (this.eventData.status === 'draft'){
        this.publish()
      }

      if (this.eventData.status === 'published'){
        this.unPublish()
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
