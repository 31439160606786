<template>
  <b-modal
    id="add-or-update-event-block"
    :visible="showEventReservationModal"
    centered
    size="lg"
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:show-event-reservation-modal', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Make Event Reservation
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="new_user_reservation_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateReservation)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <h4>Personal Information</h4>
            <b-row>
              <b-col md="6">
                <b-form-group label="First name" label-for="first_name">
                  <template #label>
                    <span>First name<sup style="color: tomato">*</sup></span>
                  </template>

                  <validation-provider #default="{ errors }" name="First Name" rules="required">
                    <div class="exxtra-input">
                      <b-form-input id="first_name" v-model="new_data.first_name" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Last name" label-for="last_name">
                  <template #label>
                    <span>Last name<sup style="color: tomato">*</sup></span>
                  </template>

                  <validation-provider #default="{ errors }" name="Last Name" rules="required">
                    <div class="exxtra-input">
                      <b-form-input id="last_name" v-model="new_data.last_name" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group label="Email" label-for="email">
                  <template #label>
                    <span>Email<sup style="color: tomato">*</sup></span>
                  </template>

                  <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
                    <div class="exxtra-input">
                      <b-form-input id="login-username" v-model="new_data.email" type="email" name="register-email" placeholder="" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Phone" label-for="phone">
                  <template #label>
                    <span>Phone<sup style="color: tomato">*</sup></span>
                  </template>

                  <validation-provider #default="{ errors }" name="Phone" rules="required|length:10" vid="phone">
                    <div class="exxtra-input">
                      <b-form-input id="phone" v-model="new_data.phone" name="register-phone" placeholder="" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group label="Gender" label-for="gender" class="">
                  <template #label>
                    <span>Gender<span style="color: tomato">*</span></span>
                  </template>

                  <validation-provider #default="{ errors }" name="gender" rules="required">
                    <v-select
                      id="gender"
                      v-model="new_data.gender"
                      :loading="loading"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group label="Institution" label-for="institution">
                  <template #label>
                    <span>Institution<span style="color: tomato">*</span></span>
                  </template>

                <validation-provider #default="{ errors }" name="institution" rules="required">
                    <input id="institution" v-model="new_data.institution" placeholder="Enter Institution name here" class="form-control">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group label="Applicant Status" label-for="applicant_status">
                  <template #label>
                    <span>Applicant Status<span style="color: tomato">*</span></span>
                  </template>

                  <validation-provider #default="{ errors }" name="applicant_status" rules="required">
                    <v-select
                      id="applicant_status"
                      v-model="new_data.applicant_status"
                      :loading="loading"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[
                        {
                          label: 'Student',
                          value: 'Student'
                        },
                        {
                          label: 'Alumni',
                          value: 'Alumni'
                        }
                      ]"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Sector" label-for="sector">
                  <template #label>
                    <span>Sector<span style="color: tomato">*</span></span>
                  </template>

                  <validation-provider #default="{ errors }" name="sector" rules="required">
                    <v-select
                      id="sector"
                      v-model="new_data.sector"
                      :loading="loading"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sectorOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <h4 class="mt-3">
              Event Information
            </h4>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Event"
                  rules="required"
                >
                  <b-form-group
                    label="Event"
                    label-for="event"
                    autofocus
                    :state="getValidationState(validationContext)"
                  >
                    <template #label>
                      <span>Event<sup style="color: tomato">*</sup></span>
                    </template>
                    
                    <v-select
                      v-model="new_data.event_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="eventOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="event"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-if="requiresAccommodation"
                  #default="validationContext"
                  name="Accommodation"
                  rules="required"
                >
                  <b-form-group
                    label="Accommodation"
                    label-for="event"
                    autofocus
                    :state="getValidationState(validationContext)"
                  >
                    <template #label>
                      <span>Accommodation<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      v-model="new_data.accommodation_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="eventAccommodationOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="event"
                      :disabled="!new_data.event_id"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  v-if="requiresCanteen"
                  #default="validationContext"
                  name="Canteen"
                  rules="required"
                >
                  <b-form-group
                    label="Canteen"
                    label-for="event_center"
                    autofocus
                    :state="getValidationState(validationContext)"
                  >
                    <template #label>
                      <span>Canteen<sup style="color: tomato">*</sup></span>
                    </template>

                    <v-select
                      v-model="new_data.canteen_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="eventCanteenOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="event_center"
                      :disabled="!new_data.event_id"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-if="requiresProduct"
                  #default="validationContext"
                  name="Products"
                  rules=""
                >
                  <b-form-group
                    label="Products"
                    label-for="products"
                    autofocus
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="new_data.products"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="eventProductOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="products"
                      :disabled="!new_data.event_id"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <validation-provider
              #default="validationContext"
              name="Cash Payment Received?"
              rules="required"
            >
              <b-form-group
                label="Cash Payment Received?"
                label-for="cash_payment_received"
              >
                <b-form-checkbox
                  id="cash_payment_received"
                  v-model="new_data.cash_payment_received"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <h1 v-if="totalCost">
              Cost: GHS {{ formatMoney(totalCost) }}
            </h1>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                Make Reservation
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                :disabled="disableForm"
                @click="() => onCreateReservation(false)"
              >
                Save And Add Another Reservation
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton,
  BAlert, BCardText, BModal, BFormCheckbox, BRow, BCol
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { get, cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min_value, max_value, numeric } from '@validations';

import RegistrationMail from "@/assets/svg/RegistrationMail.svg";
import RegistrationPhone from "@/assets/svg/RegistrationPhone.svg";

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

const rawEventCenterAccommodationData = {
  cash_payment_received: true,
  event_id: "",
  accommodation_id: "",
  canteen_id: "",
  user_id: "",
  products: [],
  email: "",
  phone: "",
  first_name: "",
  last_name: "",
  gender: '',
  institution: '',
  sector: '',
  applicant_status: '',
}

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BAlert,
    BCardText,
    vSelect,
    BModal,
    RegistrationPhone,
    RegistrationMail,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showEventReservationModal',
    event: 'update:show-event-reservation-modal',
  },
  props: {
    showEventReservationModal: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      numeric,
      required,
      min_value,
      max_value,
      eventCenters: [],
      events: [],
      eventAccommodations: [],
      eventProducts: [],

      loading: false,
      errorMessage: "",
      event_center_blocks: [],
      new_data: {
        ...cloneDeep(rawEventCenterAccommodationData),
      },

      genderOptions: [
        {
          value: "female",
          label: "Female"
        },
        {
          value: "male",
          label: "Male"
        }
      ],
      sectorOptions: [
        "Not applicable",
        "Madina Sector",
        "Kaneshie Sector",
        "Upper Volta Sector",
        "Lower Volta Sector", 
        "Mankessim Sector",
        "Sunyani Sector",
        "Cape Coast Sector",
        "Techiman Sector",
        "Takoradi Sector",
        "Tarkwa Sector",
        "Koforidua Sector",
        "Suhum Sector",
        "Bompata Sector",
        "Kwadaso Sector",
        "Mampong Sector", 
        "Northern Sector",
        "Upper East Sector", 
        "Upper West sector",
        "KNUST CAMPUS",
        "UCC CAMPUS",
        "UEW CAMPUS",
        "UG CAMPUS",
        "PU CAMPUS",
      ].map(title => ({
        label: title,
        value: title
      })),
    }
  },
  computed: {
    disableForm() {
      return !this.can('reservations:CanAddNew')
    },
    selectedEventId() {
      return this.new_data.event_id
    },
    selectedEvent() {
      return this.events.find(event => event._id === this.new_data.event_id)
    },
    selectedAccommodationId() {
      return this.new_data.accommodation_id
    },
    accommodationPrice() {
      if (!this.selectedAccommodation || !this.selectedEvent) { return 0 }
      return this.selectedAccommodation.price
    },
    selectedAccommodation() {
      return this.selectedEvent && this.selectedEvent.accommodations_data.find(accm => accm._id === this.selectedAccommodationId)
    },
    requiresAccommodation() {
      if (this.selectedEvent) {
        return this.selectedEvent.require_accommodation
      }
      return true;
    },
    requiresCanteen() {
      if (this.selectedEvent) {
        return this.selectedEvent.require_canteen
      }
      return true;
    },
    eventOptions() {
      return this.events.map(event => ({ label: event.title, value: event._id }))
    },
    eventAccommodationOptions() {
      if (!this.selectedEventId) { return [] }
      const event = this.events.find(e => e._id === this.selectedEventId)
      if (event) {
        return event.accommodations_data.map(accm => ({ label: accm.title, value: accm._id }))
      }
      return []
    },
    eventCanteenOptions() {
      if (!this.selectedEventId) { return [] }
      const event = this.events.find(e => e._id === this.selectedEventId)
      if (event) {
        return event.canteens_data.map(accm => ({ label: accm.title, value: accm._id }))
      }
      return []
    },
    eventProductOptions() {
      if (!this.selectedEventId) { return [] }
      const event = this.events.find(e => e._id === this.selectedEventId)
      if (event) {
        return this.eventProducts.map(accm => ({ label: `${accm.name} - GHS${this.formatMoney(accm.price)}`, value: accm.product_id }))
      }
      return []
    },
    productsPrice() {
      if (!this.selectedEvent) { return 0 }
      const selectedProducts = this.eventProducts.filter(p => this.new_data.products.includes(p.product_id));
      return selectedProducts.reduce((prev, curr) => prev + curr.price, 0)
    },
    totalCost() {
      return this.accommodationPrice + this.productsPrice
    },
    requiresProduct() {
      if (this.selectedEvent) {
        return this.selectedEvent.require_product
      }
      return true;
    }
  },
  watch: {
    'new_data.event_id': {
      handler(event_id) {
        event_id && this.updateEventProducts(event_id)
      }
    }
  },
  created() {
    this.fetchUpcomingEvents()
  },
  methods: {
    async onCreateReservation(closeModal = true){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.new_user_reservation_form.validate();
        if (!success){
          return;
        }

        const title = "Make event reservation for user"
        const text = "You are about to make an event reservation for a user"
        const icon = "info"

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }

        const payload = { ...this.new_data }
        if (!payload.canteen_id) {
          delete payload.canteen_id
        }

        if (!payload.accommodation_id) {
          delete payload.accommodation_id
        }
        
        await this.useJwt().adminService.makeReservationForUser(payload);

        if (closeModal) {
          this.$emit('update:show-event-reservation-modal', false);
        } else {
          this.resetForm()
        }
        this.$emit("refresh");

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Event reservation successful.`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchUpcomingEvents() {
      try {
        this.loading = true;

        const request = await this.useJwt().adminService.fetchEvents({
          populate: [
            "accommodations_data",
            "canteens_data",
            "products_data"
          ]
        });
        const { data } = request.data;

        this.events = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async updateEventProducts(event_id) {
      try {
        this.loading = true;

        const response = await this.useJwt().adminService.fetchEventProducts(event_id);
        const { data } = response.data;

        this.eventProducts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.new_data = {};
      if (this.$refs.new_user_reservation_form) {
        this.$refs.new_user_reservation_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update-event-block {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
