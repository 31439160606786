<template>
  <div>
    <add-or-update-caterer-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      :update-data="updateData"
      @refetchData="fetchAllData"
      @resetUpdateData="resetUpdateData"
    />
    
    <list-table
      :records="caterers"
      :columns="tableColumns"
      :total-records="totalRecords"
      :search-filter.sync="search"
      :show-search="true"
      :show-export-button="true"
      :show-filter-by-default="true"
      @export="onExport"
    >
      <template v-if="can('caterers:CanAddNew')" #action_button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="isAddOrUpdateActive = true"
        >
          <span class="align-middle text-lg">Add New Caterer</span>
        </b-button>
      </template>

      <template #cell(avatar)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="40"
              :src="getValueFromSource(data, 'item.avatar.path')"
              :text="sentenceCase(avatarText(`${data.item.name}`))"
            />
          </template>

          <span class="d-inline-block" style="margin-top: 6px">{{ data.item.name }}</span><br />
        </b-media>
      </template>

      <template #cell(description)="data">
        <span>
          {{ data.item.description || '-' }}
        </span>
      </template>

      <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(data.item.status) }}
          </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item v-if="can('caterers:CanUpdate')" @click="onEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

           <b-dropdown-item v-if="can('caterers:CanDelete')" @click="onDelete(data.item._id)">
            <feather-icon icon="DeleteIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddOrUpdateCatererModal from "@core/components/shared/admin/events/AddOrUpdateCatererModal.vue";

import { debounce, get } from "lodash";
import { formatDate } from "@core/utils/utils";
import { BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListEventCenters",
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    ListTable,
    BDropdown,
    BDropdownItem,
    AddOrUpdateCatererModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      caterers: [],

      search: "",
      updateData: null,
      debounceFetchAllData: null,
      isAddOrUpdateActive: false,

      tableColumns: [
        { key: 'avatar', label: 'Caterer', sortable: false },
        { key: 'description', sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
        { key: 'actions' },
      ]
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    statusFilter: watchHandler,
    search: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      search,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.search = search;
    this.perPage = +limit;
    this.currentPage = +page;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          search: this.search,
          page: this.currentPage,
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchCaterers(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.caterers = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onExport(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export caterers based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          search: this.search,
        }

        const response = await this.useJwt().adminService.exportCaterers(query);
        FileDownload(response.data, "caterers.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async onDelete(id){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Delete?',
          text: "Do you want to delete this record?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.deleteCaterer(id);
        await this.fetchAllData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Record deleted Successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    onEdit(update){
      this.updateData = update;
      this.isAddOrUpdateActive = true;
    },
    resetUpdateData(){
      if (this.updateData){
        this.updateData = null
      }
    },
  }
}
</script>

<style scoped>

</style>
