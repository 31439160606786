<template>
  <section class="mb-2">
    <h3>{{ title }}</h3>

    <div
      class="d-md-flex flex-wrap align-items-center"
    >
      <div
        v-for="(stat, i) in reservationStatistics"
        :key="i"
        class="mr-2 w-20"
        :class="stat.customClass"
      >
        <div class="d-flex align-items-center px-2 py-1">
          <div class="">
            <div class="">
              <p class="m-0">
                {{ stat.subtitle }}
              </p>
            </div>
            <div>
              <h1 class="m-0 p-0 font-weight-bold">
                {{ stat.value }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserIcons from "@/pages/home/UserIcons.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    UserIcons,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    reservationStatistics: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px !important;
}

.w-20 {
  width: 255px !important;
}
</style>
