<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="eventData === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching event details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-events'}"
        >
          List Events
        </b-link>
      </div>
    </b-alert>

    <b-tabs v-if="eventData">
      <b-tab>
        <template #title>
          <span>General Update</span>
        </template>

        <add-or-update-event-form
          ref="eventForm" 
          :updating-event="eventData"
          @update="onUpdateEvent" 
        />
      </b-tab>

      <b-tab>
        <template #title>
          <span>Activities</span>
        </template>
      
        <list-event-activities 
          :add-activity="addActivity"
          :update-activity="updateActivity"
          :remove-activity="removeActivity"
          :event-activities="filteredActivities" 
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import { BOverlay, BAlert, BLink, BTabs, BTab, BButton } from "bootstrap-vue"
import { get, omit } from "lodash"

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListEventActivities from "@/@core/components/shared/admin/events/ListEventActivities.vue"
import AddOrUpdateEventForm from "@/@core/components/shared/admin/events/AddOrUpdateEventForm.vue"

export default {
  components: {
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BButton,
    ListEventActivities,
    AddOrUpdateEventForm,
  },
  directives: {},
  data() {
    return {
      loading: false,
      eventData: null
    }
  },
  computed: {
    filteredActivities() {
      return (this.eventData.event_activities || []).filter(act => act.status === 'active')
    }
  },
  created(){
    this.fetchEvent()
  },
  methods: {
    async fetchEvent() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().adminService.fetchEvent(id);
        const { data } = request.data;

        this.eventData = data;
      } catch (error) {
        this.eventData = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateEvent(updateData){
      try {
        this.loading = true;

        await this.useJwt().adminService.updateEvent(this.eventData._id, updateData);
        this.$refs.eventForm.resetForm();
    
        await this.$router.push({
          name: "admin-events"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Event updated successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async addFieldDraft(fieldDraft){
      try {
        this.loading = true;

        await this.useJwt().adminService.addFieldDraft(this.eventData._id, fieldDraft);
        this.fetchEvent();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async updateFieldDraft(updateData){
      try {
        this.loading = true;
        
        await this.useJwt().adminService.updateField(this.eventData._id, updateData._id, omit(updateData, ['_id', 'status']));
        await this.fetchEvent();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async removeFieldDraft(field_id) {
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Delete this custom field?',
          text: "Do you want to delete this field?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value){
          return;
        }

        await this.useJwt().adminService.deleteField(this.eventData._id, field_id);
        await this.fetchEvent();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async addActivity(fieldDraft){
      try {
        this.loading = true;

        await this.useJwt().adminService.addActivity(this.eventData._id, fieldDraft);
        this.fetchEvent();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async updateActivity(updateData){
      try {
        this.loading = true;
        await this.useJwt().adminService.updateActivity(this.eventData._id, updateData._id, omit(updateData, ['_id', 'status']));
        await this.fetchEvent();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async removeActivity(activity_id) {
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Delete this activity?',
          text: "Do you want to delete this activity?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value){
          return;
        }

        await this.useJwt().adminService.deleteActivity(this.eventData._id, activity_id);
        await this.fetchEvent();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
