var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-or-update","visible":_vm.isAddActive,"centered":"","hide-footer":"","hide-header":"","no-close-on-esc":"","no-close-on-backdrop":""},on:{"hidden":_vm.resetForm,"hide":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-overlay',{staticClass:"px-2",attrs:{"show":_vm.loading}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header py-1",staticStyle:{"border-bottom":"1px solid #000000"}},[_c('h5',{staticClass:"mb-0",staticStyle:{"font-weight":"400","font-size":"14px","line-height":"160%"}},[_vm._v(" Add New Product ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"update_modal_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"my-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onCreate)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[(_vm.errorMessage)?_c('b-alert',{attrs:{"variant":"danger","show":true}},[_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)]):_vm._e(),_c('div',{staticClass:"border rounded p-2 mb-2"},[_c('label',{},[_vm._v("Featured Image")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[(_vm.featuredImagePath)?_c('b-media-aside',[_c('b-img',{staticClass:"mr-2 mb-1 mb-md-0",attrs:{"src":_vm.featuredImagePath,"height":"120","width":"120","rounded":"","fluid":""}})],1):_vm._e(),_c('b-media-body',[_c('small',{staticClass:"text-muted"},[_vm._v("Image max size 5mb.")]),_c('div',{staticClass:"d-inline-block w-100"},[_c('validation-provider',{attrs:{"name":"Avatar","rules":{
                      required: false,
                      size: 5000,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"","label-for":"avatar-file"}},[_c('b-form-file',{attrs:{"id":"avatar-file","accept":".jpg, .png, .jpeg, .webp","placeholder":"Choose file","state":errors.length > 0 ? false : null},on:{"input":_vm.onAvatarRenderer},model:{value:(_vm.featuredImage),callback:function ($$v) {_vm.featuredImage=$$v},expression:"featuredImage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])],1)],1),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name"),_c('sup',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","placeholder":"","disabled":_vm.disableForm,"state":_vm.getValidationState(validationContext)},model:{value:(_vm.new_data.name),callback:function ($$v) {_vm.$set(_vm.new_data, "name", $$v)},expression:"new_data.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Price","rules":{
              required: true,
              min_value: 1,
              max_value: 1000000
            }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Price","label-for":"price"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Price"),_c('sup',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"price","state":_vm.getValidationState(validationContext),"placeholder":"","disabled":_vm.disableForm},model:{value:(_vm.new_data.price),callback:function ($$v) {_vm.$set(_vm.new_data, "price", $$v)},expression:"new_data.price"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","state":_vm.getValidationState(validationContext),"disabled":_vm.disableForm},model:{value:(_vm.new_data.description),callback:function ($$v) {_vm.$set(_vm.new_data, "description", $$v)},expression:"new_data.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.disableForm}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }