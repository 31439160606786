<template>
  <div>
    <add-or-update-product-options-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      :update-data="updateData"
      :product-id="product._id"
      @refetchData="fetchAllData"
      @resetUpdateData="resetUpdateData"
    />
    
    <list-table
      :records="productOptions"
      :columns="tableColumns"
      :total-records="totalRecords"
      :search-filter.sync="search"
      :show-search="true"
      :show-export-button="true"
      :show-filter-by-default="true"
      @export="onExport"
    >
      <template v-if="can('product_options:CanAddNew')" #action_button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="isAddOrUpdateActive = true"
        >
          <span class="align-middle text-lg">Add New Option</span>
        </b-button>
      </template>

      <template #cell(thumbnail_image)="data">
        <b-img
          v-if="data.item.thumbnail_image.path"
          :src="data.item.thumbnail_image.path"
          height="120"
          width="120"
          rounded
          fluid
          class=""
        />
        <span v-else class="align-middle text-lg">-</span>
      </template>

      <template #cell(description)="data">
        <span>
          {{ data.item.description.length > '100' ? `${data.item.description.substr(0, 100)}...`: (data.item.description || '-') }}
        </span>
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ getUserStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item v-if="can('product_options:CanUpdate')" @click="onEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

           <b-dropdown-item v-if="can('product_options:CanDelete')" @click="onDelete(data.item._id)">
            <feather-icon icon="DeleteIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddOrUpdateProductOptionsModal from "@core/components/shared/admin/events/AddOrUpdateProductOptionsModal.vue";

import { debounce, get } from "lodash";
import { formatDate } from "@core/utils/utils";
import { BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BImg, BMedia, BBadge, BDropdown, BDropdownItem, } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListEventCenters",
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    ListTable,
    BDropdown,
    BDropdownItem,
    AddOrUpdateProductOptionsModal
  },
  directives: {
    Ripple,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      productOptions: [],

      search: "",
      updateData: null,
      debounceFetchAllData: null,
      isAddOrUpdateActive: false,

      tableColumns: [
        { key: 'thumbnail_image', label: 'Thumbnail', sortable: false },
        { key: 'title', sortable: false },
        { key: 'description', sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
        { key: 'actions' },
      ]
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    statusFilter: watchHandler,
    search: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      search,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.search = search;
    this.perPage = +limit;
    this.currentPage = +page;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          search: this.search,
          page: this.currentPage,
          product_id: this.product._id
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchProductOptions(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.productOptions = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onExport(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export canteens based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          search: this.search,
          product_id: this.product._id
        }

        const response = await this.useJwt().adminService.exportProductOptions(query);
        FileDownload(response.data, "product-options.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async onDelete(id){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Delete?',
          text: "Do you want to delete this record?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.deleteProductOption(id);
        await this.fetchAllData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Record deleted Successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    onEdit(update){
      this.updateData = update;
      this.isAddOrUpdateActive = true;
    },
    resetUpdateData(){
      if (this.updateData){
        this.updateData = null
      }
    },
  }
}
</script>

<style scoped>

</style>
