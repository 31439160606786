import axios from "axios"
import { trimEnd } from "lodash"

export default class SharedJwtServices {
  axiosIns = null

  jwtConfig = null

  customAxios = axios.create({
    baseURL: trimEnd(process.env.VUE_APP_SERVER_URL, 'admin/'),
  })

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchBlogPosts(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}`, {
      params
    })
  }

  /** faqs */
  fetchFaqs(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedFaqsEndpoint}`, {
      params
    })
  }

  /** Blog POSTS */
  fetchBlogPost(id) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}/${id}`, {})
  }

  fetchRecentBlogPosts() {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}/query/recent`, {})
  }

  submitBlogComment(blog_id, params) {
    return this.axiosIns.post(`${this.jwtConfig.shared.sharedBlogsEndpoint}/${blog_id}/comment`, params)
  }

  /** Countries */
  fetchCountries(params) {
    return this.customAxios.get('/countries', { params })
  }

  fetchGeoData(params) {
    return this.customAxios.get('/geo-data', { params })
  }

  fetchChurchRegions() {
    return this.customAxios.get('/regions', {})
  }

  fetchChurchAreas(region) {
    return this.customAxios.get(`/regions/${region}/areas`, {})
  }

  fetchChurchDistricts(region, area) {
    return this.customAxios.get(`/regions/${region}/areas/${area}/districts`, {})
  }

  fetchChurchAssemblies(region, area, district) {
    return this.customAxios.get(`/regions/${region}/areas/${area}/districts/${district}/assemblies`, {})
  }
}

