<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        :records="logs"
        :columns="tableColumns"
        :total-records="totalRecords"
        :current-page-number.sync="currentPage"
        :search-filter.sync="user_id"
        :show-export-button="true"
        :use-auto-complete-search="true"
        search-type="users"
        @export="onExportLogs"
      >
        <template #cell(user)="data">
          <b-link :to="{ name: 'admin-user-single', params: { id: getValueFromSource(data, 'item.client._id') } }">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  v-b-tooltip.hover.right="sentenceCase(`${getValueFromSource(data, 'item.client.full_name')}`)"
                  size="3rem"
                  :src="getValueFromSource(data, 'item.client.avatar.path')"
                  :variant="`light-${resolveUserRoleVariant(getValueFromSource(data, 'item.client.user_type'))}`"
                  :to="{ name: 'admin-user-single', params: { id: getValueFromSource(data, 'item.client._id') } }"
                />
              </template>

              <div>
                {{ sentenceCase(`${getValueFromSource(data, 'item.client.full_name')}`) }}
              </div>
            </b-media>
          </b-link>
        </template>

        <template #cell(event_type)="data">
          <b-badge
            pill
            class="text-capitalize"
            :variant="getValueFromSource(data, 'item.variant')"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(device_type)="data">
          <span>{{ getValueFromSource(data, 'item.device.device_type') || 'N/A' }}</span>
        </template>

        <template #cell(device_name)="data">
          <span>{{ getValueFromSource(data, 'item.device.name') || 'N/A' }}</span>
        </template>

        <template #cell(comment)="data">
          <span>{{ getValueFromSource(data, 'item.comment') || 'N/A' }}</span>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BMedia, BAvatar, BButton, VBTooltip,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { get, debounce } from "lodash"
import { formatDate } from "@core/utils/utils"

import FileDownload from "js-file-download";
import ListTable from "@core/components/ListTable/ListTable.vue";
import LogsFilter from '@core/components/shared/admin/logs/LogsFilter.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    vSelect,
    LogsFilter,
    ListTable
  },
  props: {
    tableColumns: {
      type: Array,
      default: () => {
          return [
          { key: 'user', label: 'User', sortable: false },
          { key: 'event_type', sortable: false },
          { key: 'device_type', sortable: false },
          { key: 'device_name', sortable: false },
          { key: 'ip_address', sortable: false },
          { key: 'comment', sortable: false },
          { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
        ]
      }
    },
    showFilters: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      loading: false,
      showListTable: false,

      logs: [],
      user_id: "",
      dateEnd: null,
      dateStart: null,

      searchFilter: "",
      debounceFetchAllData: null,
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    user_id: watchHandlerRequiresPageReset,
    dateEnd: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
    dateStart: watchHandlerRequiresPageReset,
    transactionTypeFilter: watchHandlerRequiresPageReset,
  },
  created(){
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      user_id,
      from,
      to,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.user_id = user_id;
    this.dateStart = from;
    this.dateEnd = to;
    this.currentPage = +page;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          from: this.dateStart,
          to: this.dateEnd,
          user_id: this.user_id,
        }
        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchLogs('client', query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.logs = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportLogs(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          from: this.dateStart,
          to: this.dateEnd,
          user_id: this.user_id
        }

        const response = await this.useJwt().adminService.exportLogs('client', query);
        FileDownload(response.data, "user-logs-export.csv");
      } catch (error) {
        console.log('error', error)
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'An error occurred, Probably there is no data to export'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .width-200 {
    width: 200px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
