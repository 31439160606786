<template>
  <div class="blog-detail-wrapper">
    <b-row>
      <!-- blogs -->
      <b-col cols="12">
        <b-card
          :img-src="getValueFromSource(blogPost, 'featured_image.path')"
          :title="getValueFromSource(blogPost, 'title')"
          img-top
          img-alt="Blog Detail Pic"
        >
          <b-media no-body>
            <b-media-aside
              vertical-align="center"
              class="mr-50"
            >
              <b-avatar
                href="javascript:void(0)"
                size="24"
                :src="getValueFromSource(blogPost, 'owner.avatar.path')"
              />
            </b-media-aside>
            <b-media-body>
              <small class="text-muted mr-50">by</small>
              <small>
                <b-link class="text-body">{{ sentenceCase(getValueFromSource(blogPost, 'owner.first_name')) }}</b-link>
              </small>
              <span class="text-muted ml-75 mr-50">|</span>
              <small class="text-muted">{{ formatDate(getValueFromSource(blogPost, 'created'), 'MMM dd, yy') }}</small>
            </b-media-body>
          </b-media>
          <div class="my-1 py-25">
            <b-badge
              pill
              class="mr-75"
              :variant="getValueFromSource(blogPost, 'status') === 'draft' ? 'light-primary' : 'light-success'"
            >
              {{ capitalizeFirstWord(getValueFromSource(blogPost, 'status')) }}
            </b-badge>
          </div>

          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content"
            v-html="getValueFromSource(blogPost, 'content')"
          ></div>

          <!-- eslint-enable -->
          <hr class="my-2">

        </b-card>
      </b-col>
      <!--/ blogs -->
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'

import { get } from "lodash";
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    blogPost: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      blogComment: ""
    }
  },
  methods: {
    kFormatter,
    async onSubmitBlogComment(){
      try {
        this.loading = true;
        
        if (!this.blogComment){
          throw new Error("Please enter comment");
        }

        const blog_id = this.getValueFromSource(this.blogPost, '_id')

        const blogComment = {
          comment: this.blogComment
        }
        
        await this.useJwt().sharedService.submitBlogComment(blog_id, blogComment);
        this.blogComment = "";

        this.$emit("refresh")

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Your comment has been added successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
