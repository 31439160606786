export default {
  // Admin Endpoints
  adminLogsEndpoint: '/logs',
  adminLoansEndpoint: '/loans',
  adminUsersEndpoint: '/users',
  adminBlogsEndpoint: '/blogs',
  adminAPIEndpoint: '/api-keys',
  adminSearchEndpoint: '/search',
  adminStoriesEndpoint: '/story',
  adminsEndpoint: '/admin-users',
  adminAuditTrail: '/audit-trail',
  adminEventEndpoint: '/events',
  adminEventCenterEndpoint: '/event-centers',
  adminEventBlockEndpoint: '/event-blocks',
  adminEventAccommodationEndpoint: '/event-accommodations',
  adminCatererEndpoint: '/caterers',
  adminSpeakerEndpoint: '/speakers',
  adminCanteenEndpoint: '/canteens',
  adminProductEndpoint: '/products',
  adminProductOptionEndpoint: '/product-options',
  adminReservationsEndpoint: '/reservations',
  adminBulkSmsEndpoint: '/bulk-sms',
  adminTrusteesEndpoint: '/trustees',
  adminDatasetsEndpoint: '/datasets',
  adminSettingsEndpoint: '/settings',
  adminPoliciesEndpoint: '/policies',
  adminsStartersEndpoint: '/starters',
  adminReferralsEndpoint: '/referrals',
  adminMerchantsEndpoint: '/merchants',
  adminOMCAdminsEndpoint: '/omc-admin',
  adminDashboardEndpoint: '/dashboard',
  adminWorkflowsEndpoint: '/workflows',
  adminLoanTermsEndpoint: '/loan-terms',
  adminOMCBranchesEndpoint: '/omc-branch',
  adminCsvUploadsEndpoint: '/csv-uploads',
  adminBankAPIEndpoint: '/banks/api-keys',
  adminOMCAccountsEndpoint: '/omc-account',
  adminPermissionsEndpoint: '/permissions',
  adminGraduateLoanEndpoints: '/graduates',
  adminBankBucketsEndpoint: '/bank-buckets',
  adminTestimonialsEndpoint: '/testimonials',
  adminTransactionsEndpoint: '/transactions',
  adminClientGroupsEndpoint: '/client-groups',
  adminSampleCSVFileEndpoint: '/sample-csv-file',
  adminOMCSettlementsEndpoint: '/omc-settlement',
  adminSupportMessageEndpoint: '/support-message',
  adminSupportTicketsEndpoint: '/support-tickets',
  adminPartnerAccountsEndpoint: '/partner-account',
  adminDefaultNoticeEndpoint: '/loan-default-notice',
  adminPartnerAdminsEndpoint: '/partner-administrator',
  adminSendgridTemplatesEndpoint: '/sendgrid/templates',
  adminExportTemplatesEndpoint: "/csv/exports/templates",
  adminExportGraduateLoanEndpoints: '/csv/graduates/export',
  paidLoansStatusIntegrityEndpoint: '/loans/status-integrity',
}
