<template>
  <b-modal
    id="add-or-update-event-block"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            {{ isUpdate ? 'Update Accommodation' : 'Add New Accommodation' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <div class="border rounded p-2 mb-2">
              <label class="">Thumbnail</label>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside v-if="thumbnailImagePath">
                  <b-img
                    :src="thumbnailImagePath"
                    height="120"
                    width="120"
                    rounded
                    fluid
                    class="mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>

                <b-media-body>
                  <small class="text-muted">Image max size 5mb.</small>
                  <div class="d-inline-block w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Thumbnail"
                      :rules="{
                        required: false,
                        size: 5000,
                      }"
                    >
                      <b-form-group
                        label=""
                        label-for="thumbnail-file"
                      >
                        <b-form-file
                          id="thumbnail-file"
                          v-model="thumbnailImage"
                          accept=".jpg, .png, .jpeg, .webp"
                          placeholder="Choose file"
                          :state="errors.length > 0 ? false : null"
                          @input="onAvatarRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-media-body>
              </b-media>
            </div>

            <validation-provider
              #default="validationContext"
              name="Event Center"
              rules="required"
            >
              <b-form-group
                label="Event Center"
                label-for="event_center"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.event_center_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventCenters"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="event_center"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Event Center Block"
              rules="required"
            >
              <b-form-group
                label="Event Center Block"
                label-for="event_center_block"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.event_block_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="event_center_blocks"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="event_center_block"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="new_data.title"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Description"
              rules=""
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="new_data.description"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Number Of Rooms"
              :rules="{
                integer: true,
                numeric: true,
                required: true,
                min_value: 1,
                max_value: 1000
              }"
            >
              <b-form-group
                label="Number Of Rooms"
                label-for="number_of_rooms"
              >
                <b-form-input
                  id="number_of_rooms"
                  v-model="new_data.number_of_rooms"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Number Of Persons In A Room"
              :rules="{
                integer: true,
                numeric: true,
                required: true,
                min_value: 1,
                max_value: 1000
              }"
            >
              <b-form-group
                label="Number Of Persons In A Room"
                label-for="number_of_persons_per_room"
              >
                <b-form-input
                  id="number_of_persons_per_room"
                  v-model="new_data.number_of_persons_per_room"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Is Free?"
              rules="required"
            >
              <b-form-group
                label="Is Free?"
                label-for="is_free"
              >
                <b-form-checkbox
                  id="is_free"
                  v-model="new_data.is_free"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="!new_data.is_free"
              #default="validationContext"
              name="Allow Partial Payment?"
              rules="required"
            >
              <b-form-group
                label="Allow Partial Payment?"
                label-for="payment_installment"
              >
                <b-form-checkbox
                  id="payment_installment"
                  v-model="new_data.payment_installment"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="!new_data.is_free"
              #default="validationContext"
              name="Price"
              :rules="{
                required: true,
                min_value: 1,
                max_value: 1000000
              }"
            >
              <b-form-group
                label="Price"
                label-for="price"
              >
                <b-form-input
                  id="price"
                  v-model="new_data.price"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Gender"
              rules="required"
            >
              <b-form-group
                label="Gender"
                label-for="gender"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="gender_options"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="gender"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                {{ isUpdate ? 'Update' : 'Add' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton,
  BAlert, BCardText, BModal, BFormCheckbox,
  BMedia, BMediaBody, BMediaAside, BFormFile,
  BImg
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required, min_value, max_value, numeric } from '@validations'
import { get, cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

const rawEventCenterAccommodationData = {
  event_center_id: "",
  event_block_id: "",
  title: "",
  description: "",
  number_of_rooms: 1,
  number_of_persons_per_room: 1,
  price: 0,
  is_free: 0,
  payment_installment: 0,
  gender: 'both',
}

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BAlert,
    BCardText,
    vSelect,
    BModal,
    BMedia,
    BMediaBody,
    BMediaAside,
    BFormFile,
    BImg,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
    eventCenters: {
      required: true,
      type: Array
    },
    updateData: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      numeric,
      required,
      min_value,
      max_value,

      loading: false,
      errorMessage: "",
      event_center_blocks: [],
      gender_options: [
        {
          label: 'Both',
          value: 'both'
        },
        {
          label: 'Female Only',
          value: 'female-only'
        },
        {
          label: 'Male Only',
          value: 'male-only'
        }
      ],
      thumbnailImagePath: "",
      thumbnailImage: null,
      new_data: cloneDeep(rawEventCenterAccommodationData),
    }
  },
  computed: {
    isUpdate(){
      return !!this.updateData
    },
    disableForm(){
      if (this.isUpdate){
        return !this.can("event_accommodation:CanUpdate")
      }

      return !this.can("event_accommodation:CanAddNew")
    },
  },
  watch: {
    updateData: {
      handler(updateData){
        if (updateData){
          this.new_data = {
            _id: updateData._id,
            title: updateData.title,
            gender: updateData.gender,
            is_free: updateData.is_free,
            price: updateData.price.toFixed(2),
            description: updateData.description,
            event_block_id: updateData.event_block_id,
            number_of_rooms: updateData.number_of_rooms,
            number_of_persons_per_room: updateData.number_of_persons_per_room,
            event_center_id: updateData.event_center_id,
            payment_installment: updateData.payment_installment,
          }
          this.thumbnailImagePath = this.getValueFromSource(updateData, 'thumbnail_image.path');
        }
      },
      immediate: true,
      deep: true
    },
    'new_data.event_center_id': {
      handler(event_center_id){
        if (this.new_data.event_block_id && !this.isUpdate){
          this.new_data.event_block_id = ""
        }

        if (event_center_id){
          this.fetchEventCenterBlocks(event_center_id)
        } else {
          this.event_center_blocks = []
        }
      }, 
      immediate: true
    },
    'new_data.is_free': {
      handler(is_free){
        if (!is_free && !this.isUpdate){
          this.new_data.payment_installment = false;
          this.new_data.price = 0;
        }
      },
      immediate: true
    } 
  },
  methods: {
    async fetchEventCenterBlocks(event_center_id) {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
          event_center: event_center_id
        }

        const request = await this.useJwt().adminService.fetchEventCenterBlocks(query);
        const { data } = request.data;

        this.event_center_blocks = data.map((event_block) => ({ label: event_block.title, value: event_block._id }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            variant: 'danger',
            text: error_message,
            icon: 'AlertTriangleIcon',
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const messages = {
          update: {
            title: "Confirm Event Center Accommodation Update",
            text: "You are about to update details of an event center accommodation.",
            icon: "warning"
          },
          new: {
            title: "Create Event Center Accommodation",
            text: "You are about to create an event center accommodation",
            icon: "info"
          }
        }

        /* eslint-disable no-nested-ternary */
        const message = this.isUpdate ? messages.update : messages.new;
        const { title, text, icon } = message;

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }

        const formData = new FormData();

        if (this.thumbnailImage){
          formData.append('thumbnail_image', this.thumbnailImage)
        }

        Object.keys(this.new_data).forEach(key => {
          const value = this.new_data[key]
          if (Array.isArray(value)){
            value.forEach(val => {
              formData.append(key, val);
            })
          } else {
            formData.append(key, value);
          }
        })
        
        if (this.isUpdate) {
          const event_accommodation_id = this.new_data._id
          formData.delete("_id")
          if (!this.can("event_accommodation:CanUpdate")) {
            return
          }
          await this.useJwt().adminService.updateEventCenterAccommodation(event_accommodation_id, formData);
        } else {
          if (!this.can("event_accommodation:CanAddNew")) {
            return
          }
          await this.useJwt().adminService.createEventCenterAccommodation(formData);
        }
        this.$emit("refetchData");
        this.$emit('update:is-add-or-update-active', false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Event center accommodation ${this.isUpdate ? 'updated' : 'added'} successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onAvatarRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.thumbnailImagePath = reader.result
          this.thumbnailImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update-event-block {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
