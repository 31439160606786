<template>
  <b-overlay :show="loading">
    <div>
      <b-row v-if="can('users:CanList')" class="match-height">
        <b-col
          xl="12"
          md="12"
        >
          <users-statistics :user-statistics="getValueFromSource(dashboardStats, 'user_stats', userStatistics)" />
        </b-col>
        <b-col
          xl="12"
          md="12"
        >
          <reservation-statistics title="Applicant Status" :reservation-statistics="getValueFromSource(dashboardStats, 'applicant_status_stats', defaultStats.applicant_status_stats)" />
        </b-col>
        <b-col
          xl="12"
          md="12"
        >
          <reservation-statistics title="Reservation Stats" :reservation-statistics="getValueFromSource(dashboardStats, 'reservation_stats', defaultStats.reservation_stats)" />
        </b-col>

        <b-col
          xl="12"
          md="12"
        >
          <reservation-statistics title="Electronic Payments" :reservation-statistics="getValueFromSource(dashboardStats, 'electronic_payments', defaultStats.electronic_payments)" />
        </b-col>
        <b-col
          xl="12"
          md="12"
        >
          <reservation-statistics title="Cash Payments" :reservation-statistics="getValueFromSource(dashboardStats, 'cash_payments', defaultStats.cash_payments)" />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col
          xl="12"
          md="12"
        >
          <div class="mt-3">
            <SignUpChart
              :series="signUpSeries"
              :chart-options="chartOptions"
              :graph-year.sync="signUpYear"
              :loading="loading"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { get } from "lodash"
import { startOfMonth } from 'date-fns'
import { kFormatter } from '@/@core/utils/filter'
import { formatDate, formatMoney } from "@/@core/utils/utils"
import { BOverlay, BRow, BCol } from 'bootstrap-vue'


import SignUpChart from "@/pages/home/SignUpChart.vue";
import UsersStatistics from '@/pages/home/UsersStatistics.vue';
import ReservationStatistics from '@/pages/home/ReservationStatistics.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.fetchDashboardStatsData()
  }, 
  immediate: false
}

export default {
  components: {
    SignUpChart,
    BOverlay,
    BRow,
    BCol,
    UsersStatistics,
    ReservationStatistics
  },
  data(){
    const thisYear = new Date().getFullYear()
    const monthBeginning = new Date(startOfMonth(new Date()));
    
    const chartOptions = {
      chart: {
        height: 400,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        }
      },
      dataLabels: {
        enabled: false,
        formatter: (value) => kFormatter(value),
      },
      series: [
        {
          name: 'Disbursements',
          data: [
            0,
            0,
            0,
            0,
            0,
            5,
            0,
            0,
            10,
            0,
            0,
            0
          ]
        },
      ],
      colors: ["#011151", "#459042"],
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        lines: {
          show: false
        },
        type: 'datetime',
        categories: [
          `01/01/${thisYear}`,
          `02/01/${thisYear}`,
          `03/01/${thisYear}`,
          `04/01/${thisYear}`,
          `05/01/${thisYear}`,
          `06/01/${thisYear}`,
          `07/01/${thisYear}`,
          `08/01/${thisYear}`,
          `09/01/${thisYear}`,
          `10/01/${thisYear}`,
          `11/01/${thisYear}`,
          `12/01/${thisYear}`,
        ]
      },
      yaxis: {
        lines: {
          show: false
        },
        labels: {
          formatter: (value) => `¢${kFormatter(value)}`
        }
      },
      tooltip: {
        x: {
          format: 'MM/yy'
        },
        y: {
          formatter: (value) => `¢${formatMoney(value)}`
        }
      },
      legend: {
        position: "top",
        horizontalAlign: 'left'
      }
    }

    return {
      loading: false,
      chartOptions,
      userStatistics: [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          value: '0',
          subtitle: 'All users',
          customClass: 'shadow user-stat-card card users',
        },
        {
          icon: 'UserIcon',
          color: 'light-success',
          value: '0',
          subtitle: 'Verified users',
          customClass: 'shadow user-stat-card card verified',
        },
        {
          icon: 'UserIcon',
          color: 'light-warning',
          value: '0',
          subtitle: 'Unverified users',
          customClass: 'shadow user-stat-card card unverified',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          value: '0',
          subtitle: 'New signups',
          customClass: 'shadow user-stat-card card signups',
        },
      ],
      defaultSignUpSeries: [
        {
          name: 'SignUps',
          data: [
            9,
            24,
            3,
            34,
            10,
            30,
            4,
            28,
            54,
            13,
            6,
            7
          ]
        },

      ],
      dashboardStats: null,
      graphStats: null,
      from: formatDate(monthBeginning, 'yyyy-MM-dd'),
      to: formatDate(new Date(), 'yyyy-MM-dd'),
      signUpYear: new Date().getFullYear(),
      defaultStats: {
        applicant_status_stats: [
          {
            icon: 'UsersIcon',
            color: 'light-primary',
            value: 0,
            subtitle: 'Active reservations',
            customClass: 'shadow user-stat-card card my-1 signups',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Total Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
        ],
        reservation_stats: [
          {
            icon: 'UsersIcon',
            color: 'light-primary',
            value: 0,
            subtitle: 'Active reservations',
            customClass: 'shadow user-stat-card card my-1 signups',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Total Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Merchandise Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Reservations Payments',
            customClass: 'shadow user-stat-card card my-1 verified ml-2',
          }
        ],
        electronic_payments: [
          {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            value: 0,
            subtitle: 'Reservation Payments',
            customClass: 'shadow user-stat-card card my-1 signups',
          },
          {
            icon: 'ShoppingBagIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Merchandise Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Total Electronic Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
        ],
        cash_payments: [
          {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            value: 0,
            subtitle: 'Reservation Payments',
            customClass: 'shadow user-stat-card card my-1 signups',
          },
          {
            icon: 'ShoppingBagIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Merchandise Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            value: 0,
            subtitle: 'Total Cash Payments',
            customClass: 'shadow user-stat-card card my-1 verified',
          },
        ]
      }
    }
  },
  computed: {
    signUpSeries(){
      const sign_up_series = this.getValueFromSource(this.graphStats, "signups_series")
      if (sign_up_series) return sign_up_series.data

      return this.defaultSignUpSeries;
    }
  },
  watch: {
    from: watchHandler,
    to: watchHandler,
    signUpYear: {
      handler(){
        this.fetchDashboardGraphData()
      },
      immediate: true
    }
  },
  created() {
    this.fetchDashboardStatsData();
  },

  methods: {
    async fetchDashboardStatsData(){
      try {
        if (!this.can(['users:CanList'])) { return }
        this.loading = true; 

        const request = await this.useJwt().adminService.fetchDashboardStatsData({
          from: this.from,
          to: this.to
        });
        
        const { data } = request.data;
        
        this.dashboardStats = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchDashboardGraphData(){
      try {
        this.loading = true; 

        const request = await this.useJwt().adminService.fetchDashboardGraphData({
          year: this.graphYear
        });
        
        const { data } = request.data;
        
        this.graphStats = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';

  h3 {
    color: #459042;
  }
</style>
