<template>
  <b-overlay :show="loading" class="mt-5">
    <b-alert
      variant="danger"
      :show="reservation === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching reservation details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-reservations'}"
        >
          List Reservations
        </b-link>
      </div>
    </b-alert>

    <div v-if="reservation">
      <b-row class="match-height">
        <b-col cols="12" md="6">
          <b-card>
            <div class="d-flex justify-content-start">
              <b-avatar
                :src="getValueFromSource(reservation, 'user.avatar.path')"
                :text="avatarText(getValueFromSource(reservation, 'user.full_name'))"
                :variant="`light-primary`"
                size="104px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h3 class="mb-0">
                    {{ getValueFromSource(reservation, 'user.full_name') }}
                  </h3>
                  <span class="d-block card-text">{{ getValueFromSource(reservation, 'user.email') }}</span>
                  <span class="d-block card-text">{{ getValueFromSource(reservation, 'user.phone') }}</span>
                </div>
              </div>
            </div>
          </b-card>

          <b-card id="reservation-details">
            <h1>{{ reservation.event.title }}</h1>
  
            <div class="">
              <h4 class="mb-2 up-label">
                Details
              </h4>
  
              <b-row>
                <b-col md="5">
                  <p class="text-uppercase">
                    BEGIN</p>
                </b-col>
                <b-col md="7">
                  <p class="text-uppercase">
                    <strong>{{ formatDate(getValueFromSource(reservation, 'event.event_start_date'), 'MMMM dd, yyyy') }} @ {{ formatTime(getValueFromSource(reservation, 'event.event_start_time')) }}</strong>
                  </p>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col md="5">
                  <p class="text-uppercase">
                    END</p>
                </b-col>
                <b-col md="7">
                  <p class="text-uppercase">
                    <strong>{{ formatDate(getValueFromSource(reservation, 'event.event_end_date'), 'MMMM dd, yyyy') }} @ {{ formatTime(getValueFromSource(reservation, 'event.event_end_time')) }}</strong>
                  </p>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col md="5">
                  <p class="text-uppercase">
                    STATUS</p>
                </b-col>
                
                <b-col md="7">
                  <p class="text-uppercase">
                    <b-badge
                      pill
                      :variant="`light-${resolveStatusVariant(reservation.status)}`"
                    >
                      {{ capitalizeFirstWord(getValueFromSource(reservation, 'status')) }}
                    </b-badge>
                  </p>
                </b-col>
              </b-row>
  
              <b-row v-if="getValueFromSource(reservation, 'require_accommodation')">
                <b-col md="5">
                  <p class="text-uppercase">
                    ACCOMMODATION</p>
                </b-col>
                <b-col md="7">
                  <p class="text-uppercase">
                    <strong>{{ getValueFromSource(reservation, 'accommodation.title') }}</strong>
                  </p>
                </b-col>
              </b-row>
  
              <b-row v-if="getValueFromSource(reservation, 'require_canteen')">
                <b-col md="5">
                  <p class="text-uppercase">
                    CANTEEN/CATERER</p>
                </b-col>
                <b-col md="7">
                  <p class="text-uppercase">
                    <strong>{{ getValueFromSource(reservation, 'canteen.title') }}</strong>
                  </p>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col md="5">
                  <p class="text-uppercase">
                    RESERVATION CODE</p>
                </b-col>
                <b-col md="7">
                  <h3 class="text-uppercase">
                    <strong>{{ getValueFromSource(reservation, 'reservation_code') }}</strong>
                  </h3>
                </b-col>
              </b-row>

              <b-row v-if="getValueFromSource(reservation, 'cash_payment.amount')">
                <b-col md="5">
                  <p class="text-uppercase">
                    CASH PAYMENT</p>
                </b-col>
                <b-col md="7">
                  <h3 class="text-uppercase">
                    <strong>GHS {{ formatMoney(getValueFromSource(reservation, 'cash_payment.amount')) }}</strong>
                  </h3>
                </b-col>
              </b-row>

              <b-row v-if="getValueFromSource(reservation, 'cash_payment.amount')">
                <b-col md="5">
                  <p class="text-uppercase">
                    CASH PAYMENT COLLECTOR</p>
                </b-col>
                <b-col md="7">
                  <h3 class="text-uppercase">
                    <strong>{{ getValueFromSource(reservation, 'cash_payment.paid_to.full_name') }}</strong>
                  </h3>
                </b-col>
              </b-row>
  
              
              <div v-if="getValueFromSource(reservation, 'require_accommodation') && getValueFromSource(reservation, 'require_payment')">
                <hr />
  
                <b-row>
                  <b-col md="3">
                    <p class="text-uppercase">
                      RESERVATION FEE</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      <strong>GHS {{ formatMoney(getValueFromSource(reservation, 'price')) }}</strong>
                    </h3>
                  </b-col>
                </b-row>
  
                <b-row>
                  <b-col md="3">
                    <p class="text-uppercase">
                      AMOUNT PAID</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      <strong>GHS {{ formatMoney(getValueFromSource(reservation, 'amount_paid')) }}</strong>
                    </h3>
                  </b-col>
                </b-row>
  
                <b-row v-if="getValueFromSource(reservation, 'price') - getValueFromSource(reservation, 'amount_paid') > 0">
                  <b-col md="3">
                    <p class="text-uppercase">
                      BALANCE REMAINING</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      <strong>GHS {{ formatMoney(getValueFromSource(reservation, 'price') - getValueFromSource(reservation, 'amount_paid')) }}</strong>
                    </h3>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <b-button @click="printReservationDetails">
                <feather-icon icon="PrinterIcon" size="12"></feather-icon>
                Print
              </b-button>
            </div>
          </b-card>
        </b-col>
        
        <b-col cols="12" md="6">
          <b-card v-if="reservation.require_product && reservation.products.length" class="">
            <h1>Merchandise</h1>
  
            <div class="">
              <h4 class="mb-2 up-label">
                Details
              </h4>
  
              <div class="d-flex flex-wrap">
                <div v-for="(product, i) in reservation.products" :key="`product-${i}`" class="mr-1 mb-1">
                  <h4 class="m-0 mb-1">
                    {{ `${product.name}(${product.quantity})` }}
                  </h4>
                  
                  <div v-if="getValueFromSource(product, 'featured_image.path')">
                    <b-img
                      :src="getValueFromSource(product, 'featured_image.path')"
                      width="200"
                      rounded
                      class=""
                    />
                  </div>
  
                  <p class="text-info m-0">
                    Quantity: {{ product.quantity }}
                  </p>
                  <p class="text-info m-0">
                    Price: {{ `GHS ${formatMoney(product.price)}` }}
                  </p>
                </div>
              </div>
  
              <div>
                <hr />
  
                <b-row>
                  <b-col md="3">
                    <p class="text-uppercase">
                      MERCHANDISE FEE</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      <strong>GHS {{ formatMoney(getProductsCost) }}</strong>
                    </h3>
                  </b-col>
                </b-row>

                <b-row v-if="reservation.cash_payment_for_merchandise">
                  <b-col md="3">
                    <p class="text-uppercase">
                      CASH PAYMENT</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      <strong>GHS {{ formatMoney(reservation.cash_payment_for_merchandise.amount) }}</strong>
                    </h3>
                  </b-col>
                </b-row>

                <b-row v-if="reservation.cash_payment_for_merchandise">
                  <b-col md="3">
                    <p class="text-uppercase">
                      CASH PAYMENT COLLECTOR</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      {{ getValueFromSource(reservation, 'cash_payment_for_merchandise.paid_to.full_name') }}
                    </h3>
                  </b-col>
                </b-row>

                <b-row v-if="getValueFromSource(reservation, 'merchandise_payment_status') === 'success'">
                  <b-col md="3">
                    <p class="text-uppercase">
                      PAYMENT REF</p>
                  </b-col>
  
                  <b-col md="9">
                    <h3 class="text-uppercase">
                      <strong>{{ getValueFromSource(reservation, 'transaction_ref') }}</strong>
                    </h3>
                  </b-col>
                </b-row>
  
                <b-row>
                  <b-col md="3">
                    <p class="text-uppercase">
                      PAYMENT STATUS</p>
                  </b-col>
  
                  <b-col md="9">
                    <b-badge
                      pill
                      :variant="`light-${resolveStatusVariant(getValueFromSource(reservation, 'merchandise_payment_status'))}`"
                    >
                      {{ capitalizeFirstWord(getValueFromSource(reservation, 'merchandise_payment_status')) }}
                    </b-badge>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <div v-if="can('reservations:CanAddNew') && netCashPayable > 0" class="d-flex justify-content-end">
        <b-button size="lg" @click="onCollectCashPayment">
          Collect Cash Payment of <span style="font-size: x-large; font-weight: bold;"> GHS {{ formatMoney(netCashPayable) }}</span>
        </b-button>
      </div>

      <div v-if="getValueFromSource(reservation, 'require_accommodation') && getValueFromSource(reservation, 'require_payment')">
        <h3>Event Registration Payment History</h3>
        <list-table
          :records="reservationPayments"
          :columns="reservationPaymentsTableColumns"
          :total-records="totalRecords"
          :show-search="false"
        >
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(data.item.status) }}
          </b-badge>
        </template>
      </list-table>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BMediaAside, BMediaBody, BImg, BLink,
  BCard, BRow, BCol, BBadge, BCardText, BButton, BAvatar
} from 'bootstrap-vue'

import { get } from "lodash"
import { kFormatter } from '@core/utils/filter'
import { formatDate, formatMoney, base64toBlob } from "@/@core/utils/utils"

import printJS from 'print-js'
import Ripple from 'vue-ripple-directive'
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.fetchReservationPayments()
  },
  immediate: false
}

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BAlert,
    BMedia,
    BBadge,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    ListTable,
    BMediaBody,
    BFormInput,
    BMediaAside,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reservation: null,
      loading: false,
      showModal: false,
      reservationPayments: [],
      reservationPaymentsTableColumns: [
        { key: 'transaction_ref', label: 'Payment Ref', sortable: false },
        { key: 'amount', sortable: false, label: 'Amount', formatter: val => `¢${formatMoney(val)}` },
        { key: 'status', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val)}`, label: 'Date', },
      ]
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    getProductsCost() {
      return this.getValueFromSource(this.reservation, 'products', []).reduce((a, c) => a + (c.price * c.quantity), 0)
    },
    totalCostCashPayable() {
      return this.getProductsCost + this.reservation.price
    },
    totalElectronicPayments() {
      return this.reservationPayments.filter(p => p.status === 'success').reduce((prev, curr) => prev + curr.amount, 0)
    },
    totalCashPayments() {
      return this.getValueFromSource(this.reservation, 'cash_payments', []).reduce((prev, curr) => prev + curr.amount, 0)
    },
    totalAmountPaid() {
      return this.totalCashPayments + this.totalElectronicPayments
    },
    netCashPayable() {
      return this.totalCostCashPayable - this.totalAmountPaid
    }
  },
  watch: {
    reservation: {
      handler(reservation){
        if (reservation && reservation.require_payment) {
          this.fetchReservationPayments()
        }
      }, 
      deep: true, 
      immediate: true
    },
    currentPage: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.fetchReservation();
  },
  methods: {
    async fetchReservation() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;
        const request = await this.useJwt().adminService.fetchReservation(id);
        const { data } = request.data;
        this.reservation = data;
      } catch (error) {
        this.reservation = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchReservationPayments() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
        }

        const request = await this.useJwt().adminService.fetchReservationPayments(this.reservation._id, query);
        const { data, pagination } = request.data;
        
        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;

        this.reservationPayments = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onCollectCashPayment(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: "Confirm Merchandise Payment",
          text: `By proceeding you acknowledge the receipt of GHS${this.formatMoney(this.netCashPayable)}`,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }

        await this.useJwt().adminService.makeReservationPayment(this.reservation._id);
        await this.fetchReservation()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful Cash Payment',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: 'Cash payment has been processed successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async printReservationDetails() {
      try {
        this.loading = true

        const response = await this.useJwt().adminService.printReservationDetails(this.reservation._id);
        const { pdf } = response.data.data;

        this.printPDF(pdf)
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    printPDF(content) {
      const url = URL.createObjectURL(base64toBlob(content));

      printJS({
        printable: url,
        type: "pdf"
      })
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
.up-label {
    border-bottom: 5px solid rgb(2, 2, 119);
    padding-bottom: 10px;
    text-transform: uppercase;
  }
</style>
