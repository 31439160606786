<template>
  <validation-observer ref="clientGroupForm" #default="{ invalid }">
    <div>
      <h4 class="settings-card-title">
        {{ title }}
      </h4>
      <b-card no-body class="px-2 py-2">
        <b-form-group
          label-for="password_life_span_in_days"
          label-cols-sm="4"
          label-cols-lg="4"
          content-col-sm
          content-cols-lg-7
          label="Password Life Span In Days"
        >
          <validation-provider
            #default="{ errors }"
            name="Password Life Span"
            rules="required"
          >
            <b-form-input
              id="password_life_span_in_days"
              v-model="settingsData.password_life_span_in_days"
              :state="errors.length > 0 ? false : null"
              size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="days_before_password_expire_reminder"
          label-cols-sm="4"
          label-cols-lg="4"
          content-col-sm
          content-cols-lg-7
          label="Days Before Password Expire Reminder"
        >
          <validation-provider
            #default="{ errors }"
            name="Days Before Password Expire Reminder"
            rules="required"
          >
            <b-form-input
              id="days_before_password_expire_reminder"
              v-model="settingsData.days_before_password_expire_reminder"
              :state="errors.length > 0 ? false : null"
              size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="password_max_retries"
          label-cols-sm="4"
          label-cols-lg="4"
          content-col-sm
          content-cols-lg-7
          label="Password Max Retries"
        >
          <validation-provider
            #default="{ errors }"
            name="Password Max Retries"
            rules="required"
          >
            <b-form-input
              id="password_max_retries"
              v-model="settingsData.password_max_retries"
              :state="errors.length > 0 ? false : null"
              size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="lockout_duration"
          label-cols-sm="4"
          label-cols-lg="4"
          content-col-sm
          content-cols-lg-7
          label="Lockout Duration"
        >
          <validation-provider
            #default="{ errors }"
            name="Lockout Duration"
            rules="required|integer"
          >
            <b-form-input
              id="lockout_duration"
              v-model="settingsData.lockout_duration"
              :state="errors.length > 0 ? false : null"
              size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="idle_timeout"
          label-cols-sm="4"
          label-cols-lg="4"
          content-col-sm
          content-cols-lg-7
          label="Idle Timeout"
        >
          <validation-provider
            #default="{ errors }"
            name="Idle Timeout"
            rules="required|min_value:6"
          >
            <b-form-input
              id="idle_timeout"
              v-model="settingsData.idle_timeout"
              :state="errors.length > 0 ? false : null"
              size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

       <b-form-group
          label-for="otp_max_digits"
          label-cols-sm="4"
          label-cols-lg="4"
          content-col-sm
          content-cols-lg-7
          label="OTP Max Digits"
        >
          <validation-provider
            #default="{ errors }"
            name="OTP Max Digits"
            rules="required"
          >
            <b-form-input
              id="otp_max_digits"
              v-model="settingsData.otp_max_digits"
              :state="errors.length > 0 ? false : null"
              size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
          <validation-provider #default="{ errors }" name="Require Admin MFA?" rules="">
            <b-form-checkbox id="require_admin_mfa" v-model="settingsData.require_admin_mfa" name="require_admin_mfa">
              Require Admin MFA?
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
          <validation-provider #default="{ errors }" name="Require User MFA?" rules="">
            <b-form-checkbox id="require_user_mfa" v-model="settingsData.require_user_mfa" name="require_user_mfa">
              Require User MFA?
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
          <validation-provider #default="{ errors }" name="Require Admin Phone Verification?" rules="">
            <b-form-checkbox id="require_admin_phone_verification" v-model="settingsData.require_admin_phone_verification" name="require_admin_phone_verification">
              Require Admin Phone Verification?
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
          <validation-provider #default="{ errors }" name="Require Admin Phone Verification?" rules="">
            <b-form-checkbox id="require_client_phone_verification" v-model="settingsData.require_client_phone_verification" name="require_client_phone_verification">
              Require User Phone Verification?
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        
        <div>
          <b-button
            variant="primary"
            size="lg"
            :disabled="invalid"
            @click="$emit('update', { security_settings: settingsData })"
          >
            Apply
          </b-button>
        </div>
      </b-card>
    </div>
  </validation-observer>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormTextarea,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BPopover,
  VBToggle,
  BFormSpinbutton,
  BFormFile,
  BForm,
  BFormInput,
  BLink,
  BInputGroup,
} from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BPopover,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormSpinbutton,
    BFormFile,
    BForm,
    BFormInput,
    BLink,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    title: {
      type: String,
      default: "Security Settings",
    },
    securitySettings: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      settingsData: {
        loan_monthly_rate: 20,
      },
    };
  },
  watch: {
    securitySettings: {
      handler(setting) {
        this.settingsData = setting;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-card-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;

  color: #888b97;
}

input {
    width: 150px;
}
</style>
