export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: "admin-home",
    action: "read",
  },
  {
    header: 'Admins & Users',
    permissions: [
      "admin:CanList",
      "users:CanList"
    ]
  },
  {
    title: 'Administrators',
    icon: 'UserPlusIcon',
    tagVariant: 'light-warning',
    route: "admin",
    action: "read",
    permissions: ["admin:CanList"],
    childrenNestedRoutes: [
      'admin-new-user'
    ]
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    route: "admin-users",
    action: "read",
    permissions: ["users:CanList"],
  },
  {
    header: 'Events/Reservations',
    permissions: [
      "events:CanList",
      "event_block:CanList",
      "event_center:CanList",
      "event_accommodation:CanList",
      "caterers:CanList",
      "canteens:CanList",
      "admin-products",
      "admin-reservations"
    ]
  },
  {
    title: 'Setup',
    icon: 'SlidersIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Centers',
        icon: 'CastIcon',
        tagVariant: 'light-warning',
        route: "admin-event-centers",
        action: "read",
        permissions: ["event_block:CanList"],
        childrenNestedRoutes: []
      },
      {
        title: 'Blocks',
        icon: 'GridIcon',
        tagVariant: 'light-warning',
        route: "admin-event-center-blocks",
        action: "read",
        permissions: ["event_block:CanList"],
        childrenNestedRoutes: []
      },
      {
        title: 'Accommodation',
        icon: 'HomeIcon',
        tagVariant: 'light-warning',
        route: "admin-event-center-accommodation",
        action: "read",
        permissions: ["event_accommodation:CanList"],
        childrenNestedRoutes: []
      },
      {
        title: 'Caterers',
        icon: 'UsersIcon',
        tagVariant: 'light-warning',
        route: "admin-caterers",
        action: "read",
        permissions: ["caterers:CanList"],
        childrenNestedRoutes: []
      },
      {
        title: 'Canteens',
        icon: 'CoffeeIcon',
        tagVariant: 'light-warning',
        route: "admin-canteens",
        action: "read",
        permissions: ["canteens:CanList"],
        childrenNestedRoutes: []
      },
      {
        title: 'Products',
        icon: 'BoxIcon',
        tagVariant: 'light-warning',
        route: "admin-products",
        action: "read",
        permissions: ["products:CanList"],
        childrenNestedRoutes: []
      },
      {
        title: 'Speakers',
        icon: 'UsersIcon',
        tagVariant: 'light-warning',
        route: "admin-speakers",
        action: "read",
        permissions: ["speakers:CanList"],
        childrenNestedRoutes: []
      },
    ],
    childrenNestedRoutes: [
      'admin-update-product',
      // 'admin-update-permission'
    ]
  },
  {
    title: 'Events',
    icon: 'SunriseIcon',
    tagVariant: 'light-warning',
    route: "admin-events",
    action: "read",
    permissions: ["events:CanList"],
    childrenNestedRoutes: [
      'admin-new-event',
      'admin-single-event',
      'admin-update-event'
    ]
  },
  {
    title: 'Reservations',
    icon: 'BookIcon',
    tagVariant: 'light-warning',
    route: "admin-reservations",
    action: "read",
    permissions: ["reservations:CanList"],
    childrenNestedRoutes: [
      'admin-single-reservations'
    ]
  },
  {
    header: 'Payments',
    permissions: [
      "reservation_payments:CanList",
      "merchandise_payments:CanList"
    ]
  },
  {
    title: 'Electronic',
    icon: 'CreditCardIcon',
    tagVariant: 'light-warning',
    route: "admin-electronic-payments",
    action: "read",
    permissions: ["reservation_payments:CanList"]
  },
  {
    title: 'Cash',
    icon: 'DollarSignIcon',
    tagVariant: 'light-warning',
    route: "admin-cash-payments",
    action: "read",
    permissions: ["merchandise_payments:CanList"],
  },
  {
    header: 'App Settings'
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'General',
        icon: 'SettingsIcon',
        tagVariant: 'light-warning',
        route: "admin-settings",
        action: "read",
        permissions: ["settings:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-new-permission',
      'admin-update-permission'
    ]
  },
  {
    title: 'Administration',
    icon: 'MinusCircleIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Resources',
        icon: 'BoxIcon',
        tagVariant: 'light-warning',
        route: "admin-resources",
        action: "read",
        permissions: ["resource:CanList"],
      },
      {
        title: 'Policies',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-policies",
        action: "read",
        permissions: ["policy:CanList"],
      },
      {
        title: 'Roles',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-user-roles",
        action: "read",
        permissions: ["user_role:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-resources',
      'admin-new-resources',
      'admin-update-resource',
      'admin-resource-actions',
      'admin-new-resource-action',
      'admin-update-resource-action',
      'admin-policies',
      'admin-new-policy',
      'admin-user-roles',
      'admin-view-policies',
      'admin-new-user-role',
      'admin-update-user-role',
    ]
  },
  {
    header: 'Others',
    permissions: [
      "faqs:CanList",
      "blogs:CanList",
      "admin_logs:CanList"
    ]
  },
  {
    title: 'Miscellaneous',
    icon: 'CpuIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Blogs',
        action: "read",
        route: "admin-blogs",
        icon: 'ClipboardIcon',
        tagVariant: 'light-warning',
        permissions: ["blogs:CanList"],
      }
    ],
    childrenNestedRoutes: [
      'admin-new-faq',
      'admin-new-blog',
      'admin-single-faq',
      'admin-update-faq',
      'admin-single-blog',
      'admin-update-blog',
    ]
  },
  {
    title: 'Logs',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Admin Logs',
        action: "read",
        icon: 'ListIcon',
        route: "admin-logs",
        tagVariant: 'light-warning',
        permissions: ["admin_logs:CanList"]
      },
      {
        title: 'User Logs',
        action: "read",
        icon: 'ListIcon',
        route: "user-logs",
        tagVariant: 'light-warning',
        permissions: ["logs:CanList"]
      },
      {
        title: 'Audit Trail',
        action: "read",
        route: "audit-trail",
        icon: 'GitBranchIcon',
        tagVariant: 'light-warning',
        permissions: ["audit_trail:CanList"]
      },
    ],
    childrenNestedRoutes: [
      'admin-logs',
      'user-logs',
      'audit-trail'
    ]
  },
]
