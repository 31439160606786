<template>
  <b-modal
    id="add-or-update-event-block"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            {{ isUpdate ? 'Update Event Center Block' : 'Add New Event Center Block' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <validation-provider
              #default="validationContext"
              name="Event Center"
              rules="required"
            >
              <b-form-group
                label="Event Center"
                label-for="event_center"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.event_center_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventCenters"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="event_center"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="new_data.title"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Description"
              rules=""
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="new_data.description"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                {{ isUpdate ? 'Update' : 'Add' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton,
  BAlert, BCardText, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required } from '@validations'
import { get, cloneDeep, omit } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

const rawEventCenterBlockData = {
  title: "",
  description: "",
  event_center_id: "",
}

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BAlert,
    BCardText,
    vSelect,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
    eventCenters: {
      required: true,
      type: Array
    },
    updateData: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      required,
      loading: false,
      errorMessage: "",
      event_centers: [],
      new_data: cloneDeep(rawEventCenterBlockData),
    }
  },
  computed: {
    isUpdate(){
      return !!this.updateData
    },
    disableForm(){
      if (this.isUpdate){
        return !this.can("event_block:CanUpdate")
      }

      return !this.can("event_block:CanAddNew")
    },
  },
  watch: {
    updateData: {
      handler(updateData){
        if (updateData){
          this.new_data = {
            _id: updateData._id,
            title: updateData.title,
            description: updateData.description,
            event_center_id: updateData.event_center_id,
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const messages = {
          update: {
            title: "Confirm Event Center Block Update",
            text: "You are about to update details of an event center block.",
            icon: "warning"
          },
          new: {
            title: "Create Event Center Block",
            text: "You are about to create an event center block",
            icon: "info"
          }
        }

        /* eslint-disable no-nested-ternary */
        const message = this.isUpdate ? messages.update : messages.new;
        const { title, text, icon } = message;

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }
        
        if (this.isUpdate){
          const event_block_id = this.new_data._id
          const update_data = omit(this.new_data, "_id");
          if (!this.can("event_block:CanUpdate")) {
            return
          }
          await this.useJwt().adminService.updateEventCenterBlock(event_block_id, update_data);
        } else {
          if (!this.can("event_block:CanAddNew")) {
            return
          }
          await this.useJwt().adminService.createEventCenterBlock(this.new_data);
        }
        this.$emit("refetchData");
        this.$emit('update:is-add-or-update-active', false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Event center block ${this.isUpdate ? 'updated' : 'added'} successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update-event-block {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
