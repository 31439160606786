<template>
  <div>
    <add-or-update-event-center-accommodation-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      :update-data="updateData"
      :event-centers="event_centers"
      :event-center-blocks="event_center_blocks"
      @refetchData="fetchAllData"
      @resetUpdateData="resetUpdateData"
    />
    
    <list-table
      :records="event_center_accommodations"
      :columns="tableColumns"
      :total-records="totalRecords"
      :search-filter.sync="search"
      :show-search="true"
      :show-export-button="true"
      :show-filter-by-default="true"
      @export="onExportEventCenterAccommodations"
    >
      <template v-if="can('event_block:CanAddNew')" #action_button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="isAddOrUpdateActive = true"
        >
          <span class="align-middle text-lg">Add Accommodation</span>
        </b-button>
      </template>

      <template #cell(thumbnail_image)="data">
        <b-img
          v-if="data.item.thumbnail_image.path"
          :src="data.item.thumbnail_image.path"
          height="120"
          width="120"
          rounded
          fluid
          class=""
        />
        <span v-else class="align-middle text-lg">-</span>
      </template>

      <template #filters>
        <b-row>
          <b-col cols="12" md="3" class="">
            <v-select
              class="filter-select"
              placeholder="Event Center"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="eventCenterFilter"
              :options="event_centers"
              :reduce="val => val.value"
              :disabled="loading"
              @input="(val) => eventCenterFilter = val"
            />
          </b-col>
          <b-col cols="12" md="3" class="">
            <v-select
              class="filter-select"
              placeholder="Event Center Blocks"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="eventCenterBlockFilter"
              :options="event_center_blocks"
              :reduce="val => val.value"
              :disabled="loading"
              @input="(val) => eventCenterBlockFilter = val"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(show_details)="row">
        <b-button variant="outline" @click="row.toggleDetails">
          <feather-icon
            v-if="row.detailsShowing"
            icon="MinusCircleIcon"
            size="18"
          />

          <feather-icon
            v-else
            icon="PlusCircleIcon"
            size="18"
          />
        </b-button>
      </template>

      <template #row-details="row">
        <div>
          <b-table
            ref="refDataTable"
            class="position-relative rounded-lg"
            :items="[row.item]"
            :fields="tableDetailsColumn"
            responsive
            show-empty
            primary-key="_id"
            empty-text="No matching records found"
            tbody-tr-class="loan-list-tb-row"
            thead-tr-class="text-muted rounded-lg "
            thead-class="bg-light rounded-lg"
          >
            <template #cell(description)="data">
              <span>{{ data.value || '-' }}</span>
            </template>

            <template #cell(is_free)="data">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.value ? 'active' : 'deleted')}`"
                class="text-capitalize"
              >
                {{ data.value ? 'Yes' : 'No' }}
              </b-badge>
            </template>

            <template #cell(payment_installment)="data">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.value ? 'active' : 'deleted')}`"
                class="text-capitalize"
              >
                {{ data.value ? 'Yes' : 'No' }}
              </b-badge>
            </template>
          
          </b-table>
        </div>
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ getUserStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item v-if="can('event_block:CanUpdate')" @click="onEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

           <b-dropdown-item v-if="can('event_block:CanDelete')" @click="onDelete(data.item._id)">
            <feather-icon icon="DeleteIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddOrUpdateEventCenterAccommodationModal from "@core/components/shared/admin/events/AddOrUpdateEventCenterAccommodationModal.vue";

import { debounce, get } from "lodash";
import { formatDate, formatMoney } from "@core/utils/utils";
import { BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem, BTable, BImg } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListEventBlocks",
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BTable,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    ListTable,
    BDropdown,
    BDropdownItem,
    BImg,
    AddOrUpdateEventCenterAccommodationModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      event_centers: [],
      event_center_blocks: [],
      event_center_accommodations: [],

      search: "",
      eventCenterFilter: null,
      eventCenterBlockFilter: null,

      updateData: null,
      debounceFetchAllData: null,
      isAddOrUpdateActive: false,

      tableColumns: [
        { key: 'show_details', _showDetails: true, label: "#" },
        { key: 'thumbnail_image', label: 'Thumbnail' },
        { key: 'title', label: 'Title', sortable: false },
        { key: 'event_center.title', label: 'Center', sortable: false },
        { key: 'event_block.title', label: 'Block', sortable: false },
        { key: 'number_of_rooms', label: '# Of Rooms', sortable: false },
        { key: 'price', sortable: false, label: 'Price', formatter: val => `¢${formatMoney(val)}` },
        { key: 'status', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
        { key: 'actions' },
      ],
      tableDetailsColumn: [
        { key: 'description', sortable: false },
        { key: 'number_of_persons_per_room', label: 'Persons per room', sortable: false, },
        { key: 'is_free', sortable: false, },
        { key: 'payment_installment', label: 'Partial', sortable: false, },
        { key: 'gender', sortable: false },
      ],
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    search: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
    eventCenterFilter: {
      handler(event_center_id){
        this.currentPage = 1;
        this.debounceFetchAllData()

        if (this.eventCenterBlockFilter){
          this.eventCenterBlockFilter = null
        }

        if (event_center_id){
          this.fetchEventCenterBlocks(event_center_id)
        } else {
          this.event_center_blocks = [];
        }
      },
      immediate: false
    },
    eventCenterBlockFilter: watchHandlerRequiresPageReset,
  },
  created() {
    this.fetchEventCenters();
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      search,
      page = 1,
      event_center,
      event_block,
      limit = this.perPage
    } = this.$route.query;

    this.search = search;
    this.perPage = +limit;
    this.currentPage = +page;
    this.eventCenterFilter = event_center
    this.eventCenterBlockFilter = event_block
  },
  methods: {
    async fetchEventCenters() {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
        }

        const request = await this.useJwt().adminService.fetchEventCenters(query);
        const { data } = request.data;

        this.event_centers = data.map((event_center) => ({ label: event_center.title, value: event_center._id }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            variant: 'danger',
            text: error_message,
            icon: 'AlertTriangleIcon',
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchEventCenterBlocks(event_center_id) {
      try {
        this.loading = true;

        const query = {
          page: 1,
          limit: 1000,
          event_center: event_center_id
        }

        const request = await this.useJwt().adminService.fetchEventCenterBlocks(query);
        const { data } = request.data;

        this.event_center_blocks = data.map((event_block) => ({ label: event_block.title, value: event_block._id }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            variant: 'danger',
            text: error_message,
            icon: 'AlertTriangleIcon',
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          search: this.search,
          page: this.currentPage,
          event_center: this.eventCenterFilter,
          event_block: this.eventCenterBlockFilter,
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchEventCenterAccommodations(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.event_center_accommodations = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onExportEventCenterAccommodations(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export event center accommodations based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          from: this.dateStart,
          to: this.dateEnd,
          user_id: this.user_id
        }

        const response = await this.useJwt().adminService.exportEventBlocks(query);
        FileDownload(response.data, "event-center-accommodations.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async onDelete(id){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Delete?',
          text: "Do you want to delete this Event Center Accommodation?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.deleteEventCenterAccommodation(id);
        await this.fetchAllData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Event Center Accommodation Deleted Successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    onEdit(update){
      this.updateData = update;
      this.isAddOrUpdateActive = true;
    },
    resetUpdateData(){
      if (this.updateData){
        this.updateData = null
      }
    },
  }
}
</script>

<style scoped>

</style>
