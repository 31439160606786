<template>
  <b-overlay :show="loading">
    <add-or-update-event-form ref="eventsForm" @create="onCreate" />
  </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue"
import { get } from "lodash"

import AddOrUpdateEventForm from "@/@core/components/shared/admin/events/AddOrUpdateEventForm.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    AddOrUpdateEventForm
  },
  directives: {},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async onCreate(eventData){
      try {
        this.loading = true;

        await this.useJwt().adminService.createNewEvent(eventData);
        this.$refs.eventsForm.resetForm();
    
        await this.$router.push({
          name: "admin-events"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Event created successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
