<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="product === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching product details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-products'}"
        >
          List Products
        </b-link>
      </div>
    </b-alert>

    <b-tabs v-if="product">
      <b-tab>
        <template #title>
          <span>General Update</span>
        </template>

        <update-product-form
          ref="productForm" 
          :product="product"
          @update="onUpdateProduct" 
        />
      </b-tab>

      <b-tab v-if="product">
        <template #title>
          <span>Product Options</span>
        </template>
      
        <list-product-options 
          :product="product" 
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import { get } from "lodash"
import { BOverlay, BAlert, BLink, BTabs, BTab, BButton } from "bootstrap-vue"

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UpdateProductForm from "@/@core/components/shared/admin/events/UpdateProductForm.vue"
import ListProductOptions from "@/@core/components/shared/admin/events/ListProductOptions.vue"

export default {
  components: {
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BButton,
    UpdateProductForm,
    ListProductOptions,
    
  },
  directives: {},
  data() {
    return {
      loading: false,
      product: null
    }
  },
  created(){
    this.fetchProduct()
  },
  methods: {
    async fetchProduct() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().adminService.fetchProduct(id);
        const { data } = request.data;

        this.product = data;
      } catch (error) {
        this.product = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateProduct(updateForm){
      try {
        this.loading = true;

        await this.useJwt().adminService.updateProduct(this.product._id, updateForm);
        this.$refs.productForm.resetForm();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Product updated successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
