<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        ref="users_table_ref"
        :records="users" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="searchFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="can('users:CanExport')"
        :show-import-button="false"
        :import-type="importType"
        @row-clicked="onRowClicked"
        @export="onExportUsers"
        @import="onImportUserRecords"
      >
        <!-- <template v-if="can('users:CanImport')" #action_button>
          <b-dropdown text="Import" class="m-md-2" variant="primary">
            <b-dropdown-item @click="onImportOptionSelected('users')">
              Clients
            </b-dropdown-item>
            <b-dropdown-item @click="onImportOptionSelected('drivers')">
              Drivers
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

        <template #filters>
          <b-row>
            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select"
                placeholder="Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="statusFilter"
                :options="statusOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => statusFilter = val"
              />
            </b-col>
            <b-col cols="12" md="3" class="">
              <v-select
                class="filter-select"
                placeholder="Sector"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="sectorFilter"
                :options="sectorOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => sectorFilter = val"
              />
            </b-col>

            <b-col cols="12" md="3" class="">
              <v-select
                class="filter-select"
                placeholder="Applicant Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="applicantStatusFilter"
                :options="applicantStatusOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => applicantStatusFilter = val"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(avatar)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.avatar.path')"
                :text="sentenceCase(avatarText(`${data.item.full_name}`))"
                :variant="`light-${resolveUserRoleVariant(data.item.user_type)}`"
                :to="{ name: 'admin-user-single', params: { id: data.item._id } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-user-single', params: { id: data.item._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.full_name')) }}
            </b-link>
            <span class="text-muted">{{ data.item.email }}</span><br />
            <span class="text-muted">@{{ data.item.phone }}</span>
          </b-media>
        </template>

        <template #cell(national_ID)="data">
          <span>{{ getValueFromSource(data, 'item.national_ID') || '-' }}</span>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            
            <b-dropdown-item :to="{ name: 'admin-user-single', params: { id: data.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'admin-user-single', params: { id: data.item._id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item @click="() => makeUserEventReservation(data.item._id)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Make Event Reservation</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </list-table>
    </div>
    <MakeReservationForUserModal :show-event-reservation-modal.sync="showEventReservationModal" :user-id="reservationUserId" @close="showEventReservationModal = false" />
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput
} from 'bootstrap-vue'
import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MakeReservationForUserModal from "@/@core/components/shared/admin/reservations/MakeReservationForUserModal.vue"

const watchHandler = {
  handler(){
    this.debouncedFetchUsersData()
  }, 
  immediate: false
}
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BInputGroupAppend, 
    BFormInput,
    MakeReservationForUserModal,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    showAddUserButton: {
      type: Boolean,
      default: true
    },
  },
  data(){
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      statusFilter: null,
      searchFilter: "",
      sectorFilter: "",
      applicantStatusFilter: "",
      users: [],
      importType: "",
      reservationUserId: "",
      showEventReservationModal: false,
      tableColumns: [
        { key: 'avatar', stickyColumn: true, sortable: false, label: 'User' },
        { key: 'user_type', sortable: false, label: 'User Type' },
        { key: 'sector', sortable: false, label: 'Sector' },
        { key: 'applicant_status', sortable: false, label: 'Applicant Status' },
        { key: 'status', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
        { key: 'actions' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'In-Active', value: 'inactive' },
        { label: 'Blocked', value: 'blocked' },
        { label: 'Pending Email Verification', value: 'pending_confirmation' },
        { label: 'Pending Phone Verification', value: 'pending_sms' }
      ],
      applicantStatusOptions: [
        {
          label: "Student",
          value: "Student"
        },
        {
          label: "Alumni",
          value: 'Alumni'
        }
      ],
      sectorOptions: [
        "Not applicable",
        "Madina Sector",
        "Kaneshie Sector",
        "Upper Volta Sector",
        "Lower Volta Sector", 
        "Mankessim Sector",
        "Sunyani Sector",
        "Cape Coast Sector",
        "Techiman Sector",
        "Takoradi Sector",
        "Tarkwa Sector",
        "Koforidua Sector",
        "Suhum Sector",
        "Bompata Sector",
        "Kwadaso Sector",
        "Mampong Sector", 
        "Northern Sector",
        "Upper East Sector", 
        "Upper West sector",
        "KNUST CAMPUS",
        "UCC CAMPUS",
        "UEW CAMPUS",
        "UG CAMPUS",
        "PU CAMPUS",
      ].map(title => ({
        label: title,
        value: title
      })),
      debouncedFetchUsersData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchUsersData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
    sectorFilter: watchHandler,
    applicantStatusFilter: watchHandler,
  },
  created(){
    this.debouncedFetchUsersData = debounce(this.fetchAllData, 500);

    const { 
      status, 
      page = 1, 
      search = "", 
      sector = "",
      applicant_status = "",
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.sectorFilter = sector;
    this.applicantStatusFilter = applicant_status;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
          search: this.searchFilter,
          sector: this.sectorFilter,
          applicant_status: this.applicantStatusFilter,
        }
        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchUsers(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.users = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportUsers(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          status: this.statusFilter,
        }

        const response = await this.useJwt().adminService.exportUsers(query);
        FileDownload(response.data, "users-export.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async onImportUserRecords(file){
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("users_csv", file);
        formData.append("import_type", this.importType);

        const response = await this.useJwt().adminService.importUsers(formData);
        const { data } = response.data;

        this.$router.push({ 
          name: "admin-users-import-report", 
          params: { report_id: data._id } 
        });
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "User records import has been processed successfully."
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          }
        });
      } finally {
        this.loading = false;
      }
    },
    onImportOptionSelected(import_type) {
      this.importType = import_type
      const table_ref = this.$refs.users_table_ref;
      if (table_ref) {
        table_ref.onTriggerSelectImportLoanRecordsCsvFile()
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'admin-user-single', params: { id: rowItem._id } })
      .catch(() => {})
    },
    makeUserEventReservation(userId) {
      this.reservationUserId = userId;
      this.showEventReservationModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
