<template>
  <b-modal
    id="add-or-update"
    :visible="isAddActive"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Add New Product
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <div class="border rounded p-2 mb-2">
              <label class="">Featured Image</label>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside v-if="featuredImagePath">
                  <b-img
                    :src="featuredImagePath"
                    height="120"
                    width="120"
                    rounded
                    fluid
                    class="mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>

                <b-media-body>
                  <small class="text-muted">Image max size 5mb.</small>
                  <div class="d-inline-block w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Avatar"
                      :rules="{
                        required: false,
                        size: 5000,
                      }"
                    >
                      <b-form-group
                        label=""
                        label-for="avatar-file"
                      >
                        <b-form-file
                          id="avatar-file"
                          v-model="featuredImage"
                          accept=".jpg, .png, .jpeg, .webp"
                          placeholder="Choose file"
                          :state="errors.length > 0 ? false : null"
                          @input="onAvatarRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <template #label>
                  <span>Name<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-input
                  id="name"
                  v-model="new_data.name"
                  autofocus
                  placeholder=""
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Price"
              :rules="{
                required: true,
                min_value: 1,
                max_value: 1000000
              }"
            >
              <b-form-group
                label="Price"
                label-for="price"
              >
                <template #label>
                  <span>Price<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-input
                  id="price"
                  v-model="new_data.price"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Description"
              rules=""
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="new_data.description"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormFile,
  BAlert, BCardText, BModal, BMedia, BMediaBody, BMediaAside, BImg
} from 'bootstrap-vue'

import { get, cloneDeep } from 'lodash';
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawData = {
  name: "",
  price: "",
  description: ""
}

export default {
  components: {
    BImg,
    BForm,
    BAlert,
    BModal,
    BMedia, 
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormFile,
    BFormGroup,
    BMediaBody, 
    BFormInput,
    BMediaAside,
    BFormTextarea,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddActive',
    event: 'update:is-add-active',
  },
  props: {
    isAddActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      new_data: cloneDeep(rawData),
      loading: false,
      errorMessage: "",

      featuredImage: null,
      featuredImagePath: "",

      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Delete', value: 'deleted' },
      ],
      required,
    }
  },
  computed: {
    disableForm(){
      return !this.can("products:CanAddNew")
    },
  },
  watch: {},
  methods: {
    async onCreate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const result = await this.$swal({
          title: "Create Product",
          text: "You are about to create a product",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        if (!this.can("products:CanAddNew")) {
          return
        }

        const formData = new FormData();

        if (this.featuredImage){
          formData.append('featured_image', this.featuredImage)
        }
        
        Object.keys(this.new_data).forEach(key => {
          const value = this.new_data[key]
          if (Array.isArray(value)){
            value.forEach(val => {
              formData.append(key, val);
            })
          } else {
            formData.append(key, value);
          }
        })

        const response = await this.useJwt().adminService.createProduct(formData);
        const { data: product } = response.data;

        this.$emit('update:is-add-active', false);
        
        this.$router.push({
          name: 'admin-update-product',
          params: { id: product._id }
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Product added successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onAvatarRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.featuredImagePath = reader.result
          this.featuredImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      this.featuredImage = null;
      this.featuredImagePath = '';
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
