<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'dark' ? 'menu-dark' : 'menu-light'
    ]"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item" :class="!isVerticalMenuCollapsed ? 'nav-logo-full' : 'nav-logo-small'">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span v-if="isVerticalMenuCollapsed" class="brand-logo">
                <b-img
                  alt="logo"
                  class="logo-small"
                  :src="appMobileModeLogoImage"
                />
              </span>
              <span v-if="!isVerticalMenuCollapsed" class="brand-text">P E N S A</span>
              <span v-if="!isVerticalMenuCollapsed" class="text-center text-muted">ADMIN PORTAL</span>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <!-- <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    /> -->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="getMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig';
import { BLink, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';

// import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import AdminMenuItems from "@/navigation/admin";
import useAppConfig from '@core/app-config/useAppConfig';

import useVerticalNavMenu from './useVerticalNavMenu';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    getMenuItems(){
      return AdminMenuItems
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, appMobileModeLogoImage } = $themeConfig.app

    return {
      navMenuItems: [],
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appMobileModeLogoImage
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
