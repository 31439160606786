var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"clientGroupForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c('h4',{staticClass:"settings-card-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('b-card',{staticClass:"px-2 py-2",attrs:{"no-body":""}},[_c('b-form-group',{attrs:{"label-for":"password_life_span_in_days","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":"","label":"Password Life Span In Days"}},[_c('validation-provider',{attrs:{"name":"Password Life Span","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password_life_span_in_days","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.password_life_span_in_days),callback:function ($$v) {_vm.$set(_vm.settingsData, "password_life_span_in_days", $$v)},expression:"settingsData.password_life_span_in_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"days_before_password_expire_reminder","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":"","label":"Days Before Password Expire Reminder"}},[_c('validation-provider',{attrs:{"name":"Days Before Password Expire Reminder","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"days_before_password_expire_reminder","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.days_before_password_expire_reminder),callback:function ($$v) {_vm.$set(_vm.settingsData, "days_before_password_expire_reminder", $$v)},expression:"settingsData.days_before_password_expire_reminder"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"password_max_retries","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":"","label":"Password Max Retries"}},[_c('validation-provider',{attrs:{"name":"Password Max Retries","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password_max_retries","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.password_max_retries),callback:function ($$v) {_vm.$set(_vm.settingsData, "password_max_retries", $$v)},expression:"settingsData.password_max_retries"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"lockout_duration","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":"","label":"Lockout Duration"}},[_c('validation-provider',{attrs:{"name":"Lockout Duration","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lockout_duration","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.lockout_duration),callback:function ($$v) {_vm.$set(_vm.settingsData, "lockout_duration", $$v)},expression:"settingsData.lockout_duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"idle_timeout","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":"","label":"Idle Timeout"}},[_c('validation-provider',{attrs:{"name":"Idle Timeout","rules":"required|min_value:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"idle_timeout","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.idle_timeout),callback:function ($$v) {_vm.$set(_vm.settingsData, "idle_timeout", $$v)},expression:"settingsData.idle_timeout"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"otp_max_digits","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":"","label":"OTP Max Digits"}},[_c('validation-provider',{attrs:{"name":"OTP Max Digits","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"otp_max_digits","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.otp_max_digits),callback:function ($$v) {_vm.$set(_vm.settingsData, "otp_max_digits", $$v)},expression:"settingsData.otp_max_digits"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Require Admin MFA?","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"require_admin_mfa","name":"require_admin_mfa"},model:{value:(_vm.settingsData.require_admin_mfa),callback:function ($$v) {_vm.$set(_vm.settingsData, "require_admin_mfa", $$v)},expression:"settingsData.require_admin_mfa"}},[_vm._v(" Require Admin MFA? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Require User MFA?","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"require_user_mfa","name":"require_user_mfa"},model:{value:(_vm.settingsData.require_user_mfa),callback:function ($$v) {_vm.$set(_vm.settingsData, "require_user_mfa", $$v)},expression:"settingsData.require_user_mfa"}},[_vm._v(" Require User MFA? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Require Admin Phone Verification?","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"require_admin_phone_verification","name":"require_admin_phone_verification"},model:{value:(_vm.settingsData.require_admin_phone_verification),callback:function ($$v) {_vm.$set(_vm.settingsData, "require_admin_phone_verification", $$v)},expression:"settingsData.require_admin_phone_verification"}},[_vm._v(" Require Admin Phone Verification? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Require Admin Phone Verification?","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"require_client_phone_verification","name":"require_client_phone_verification"},model:{value:(_vm.settingsData.require_client_phone_verification),callback:function ($$v) {_vm.$set(_vm.settingsData, "require_client_phone_verification", $$v)},expression:"settingsData.require_client_phone_verification"}},[_vm._v(" Require User Phone Verification? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('b-button',{attrs:{"variant":"primary","size":"lg","disabled":invalid},on:{"click":function($event){return _vm.$emit('update', { security_settings: _vm.settingsData })}}},[_vm._v(" Apply ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }