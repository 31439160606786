<template>
  <div class="border rounded-lg">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div><h3>SignUps</h3></div>
        <div>
          <label>Year</label>
          <v-select
            style="width: 120px;"
            class="d-inline-block mx-50"
            :value="graphYear"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="graphYearOptions"
            :clearable="false"
            :disabled="loading"
            @input="graphYear = $event"
          />
        </div>
      </div>
      <vue-apex-charts
        type="area"
        width="100%"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import { get } from "lodash";
import { kFormatter } from '@/@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  name: "SignUpsChart",
  components: {
    VueApexCharts,
    vSelect,
  },
  data() {
    const thisYear = new Date().getFullYear()

    const graphYearOptions = [
      // thisYear
    ];

    for (let yearOption = thisYear - 5; yearOption < thisYear; yearOption += 1){
      graphYearOptions.unshift(yearOption);
    }

    graphYearOptions.unshift(thisYear);

    return {
      graphYearOptions,
      series: [],
      loading: false,
      graphYear: new Date().getFullYear()
    }
  },
  computed: {
    chartOptions() {
      const thisYear = new Date().getFullYear()
      return {
        chart: {
          height: 400,
          type: 'area',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: (value) => kFormatter(value),
        },
        series: [
          {
            name: 'Disbursements',
            data: this.series
          },
        ],
        colors: ["#011151", "#459042"],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          lines: {
            show: false
          },
          type: 'datetime',
          categories: [
            `01/01/${thisYear}`,
            `02/01/${thisYear}`,
            `03/01/${thisYear}`,
            `04/01/${thisYear}`,
            `05/01/${thisYear}`,
            `06/01/${thisYear}`,
            `07/01/${thisYear}`,
            `08/01/${thisYear}`,
            `09/01/${thisYear}`,
            `10/01/${thisYear}`,
            `11/01/${thisYear}`,
            `12/01/${thisYear}`,
          ]
        },
        yaxis: {
          lines: {
            show: false
          },
          labels: {
            formatter: (value) => `${kFormatter(value)}`
          }
        },
        tooltip: {
          x: {
            format: 'MM/yy'
          },
          y: {
            formatter: (value) => `${kFormatter(value)}`
          }
        },
        legend: {
          position: "top",
          horizontalAlign: 'left'
        }
      }
    }
  },
  watch: {
    graphYear: {
      handler() {
        this.fetchDashboardSignUpsGraphData()
      }, 
      immediate: true
    }
  },
  created() {
    this.fetchDashboardSignUpsGraphData()
  },
  methods: {
    async fetchDashboardSignUpsGraphData(){
      try {
        this.loading = true; 

        const request = await this.useJwt().adminService.fetchDashboardSignUpsGraphData({
          year: this.graphYear
        });
        
        const { data } = request.data;
        this.series = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
