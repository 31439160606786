<template>
  <section class="">

    <div class="d-md-flex pl-md-0 pl-2 justify-content-between align-items-center">

      <div v-for="(userStat, i) in userStatistics"
           :key="i"
           :class="userStat.customClass"
      >
        <div class="d-flex align-items-center ">
          <div class="icon-card d-flex align-items-center justify-content-center rounded-circle">
            <UserIcons :key="userStat.icon" :icon-type="userStat.icon" />
          </div>
          <div class="pl-md-1">
            <div class="">
              <p class="m-0 ">
                {{ userStat.subtitle }}
              </p>
            </div>
            <div>
              <h1 class="m-0 p-0 font-weight-bold">
                {{ userStat.value }}
              </h1>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>
</template>

<script>
import UserIcons from "@/pages/home/UserIcons.vue";
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    UserIcons,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    userStatistics: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: ""
    }
  },
}
</script>


<style lang="scss">

p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px !important;
}

.user-stat-card {
  border-radius: 10px;
  margin-left: 20px;

  .icon-card {
    width: 75px;
    height: 75px;
    margin-left: -18%;
  }
}

.users {
  .icon-card {
    background-color: #E7FDF4;
  }
}
.verified {
  .icon-card {
    background-color: #E6F5ED;
  }
}

.unverified {
  .icon-card {
    background-color: #FCE9E9;
  }
}

.signups {
  .icon-card {
    background-color: #D6FBFF;
  }
}


.dark-layout {

  .users {
    .icon-card {

      background-color: #404A5B;
    }
  }
  .verified {
    .icon-card {
      background-color: #6A7580;
    }
  }

  .unverified {
    .icon-card {
      background-color: #72717E;
    }
  }

  .signups {
    .icon-card {
      //background-color: rgb(214, 251, 255, 0.30);
      background-color: #5D6E7D;
    }
  }

}

</style>
