export default class AdminJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  search(search_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminSearchEndpoint}/${search_type}/autocomplete`, {
      params
    })
  }

  /** Admin Dashboard */
  fetchDashboardStatsData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/stats`, {
      params
    })
  }

  fetchDashboardGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/graph`, {
      params
    })
  }

  fetchDashboardSignUpsGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/signups-graph`, {
      params
    })
  }

  /** Admin Settings */
  updateGeneralSettings(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminSettingsEndpoint}/${id}`, payload)
  }


  /** Admin Users */
  createAdmin(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminsEndpoint}`, payload)
  }

  fetchAdmins(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminsEndpoint}`, {
      params
    })
  }

  fetchAdmin(user_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}`)
  }

  updateAdmin(user_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}`, payload)
  }

  resendAdminAccountConfirmationEmail(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}/account/confirmation/resend`)
  }
  /** Admin Users */

  updateUser(user_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/update`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deactivateUser(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/deactivate`, {})
  }

  approveUser(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/approve`, {})
  }

  deleteUserAvatar(user_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/avatar`, {})
  }

  resendAccountConfirmationEmail(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/account/confirmation/resend`)
  }

  resetAdminUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}/account/mfa/reset`)
  }

  resetUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/account/mfa/reset`)
  }

  fetchUsers(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminUsersEndpoint, {
      params
    })
  }

  fetchUser(user_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}`, {})
  }

  fetchAdminPolicies(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy`, { params })
  }

  deleteAdminPolicy(policy_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`)
  }

  exportUsers(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminUsersEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  importUsers(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /** Admin Permissions */
  fetchPermissions(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminPermissionsEndpoint, {
      params
    })
  }

  fetchPermission(permission_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/${permission_id}`, {})
  }

  createPermission(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminPermissionsEndpoint, payload)
  }

  updatePermission(payload = {}) {
    return this.axiosIns.put(this.jwtConfig.admin.adminPermissionsEndpoint, payload)
  }

  /** Admin Blog Posts */
  createNewBlogPost(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminBlogsEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  updateBlogPost(blog_id, formData) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminBlogsEndpoint}/${blog_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  deleteBlogPost(blog_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminBlogsEndpoint}/${blog_id}`, {})
  }

  /** Admin Logs */
  fetchLogs(log_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminLogsEndpoint}/${log_type}`, {
      params
    })
  }

  fetchAuditTrails(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminAuditTrail, {
      params
    })
  }

  exportAuditTrails(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminAuditTrail}/export`, {
      params,
      responseType: "blob"
    })
  }

  fetchUpdatedAuditTrails(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminLogsEndpoint, {
      params
    })
  }

  exportUpdatedAuditTrails(params) {
    return this.axiosIns.get(this.jwtConfig.admin, {
      params,
      responseType: "blob"
    })
  }

  exportLogs(log_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminLogsEndpoint}/${log_type}/export`, {
      params,
      responseType: "blob"
    });
  }

  createPolicy(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy`, payload)
  }

  updatePolicy(policy_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`, payload)
  }

  getPolicy(policy_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`)
  }

  getResources(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources`, {
      params
    })
  }

  createResource(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources`, payload);
  }

  getResource(resource_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`)
  }

  updateResource(resource_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`, payload)
  }

  deactivateResource(resource_id, payload) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`, payload)
  }

  createResourceAction(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action`, payload);
  }

  getResourceAction(resource_action_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`)
  }

  updateResourceAction(resource_action_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`, payload)
  }

  deleteResourceAction(resource_action_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`)
  }

  getResourceActions(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action`, {
      params
    })
  }

  fetchPermissionsSchema(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/schema`, {
      params
    })
  }

  getUserRole(user_role_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`)
  }

  getUserRoles(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role`, {
      params
    })
  }

  createUserRole(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role`, payload);
  }

  updateUserRole(user_role_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`, payload)
  }

  deleteUserRole(user_role_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`)
  }

  /** Event Center */
  createEventCenter(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminEventCenterEndpoint, payload)
  }

  updateEventCenter(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventCenterEndpoint}/${id}`, payload)
  }

  deleteEventCenter(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventCenterEndpoint}/${id}`)
  }

  fetchEventCenters(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminEventCenterEndpoint, {
      params
    })
  }

  exportEventCenters(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventCenterEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Event Center Block  */
  createEventCenterBlock(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminEventBlockEndpoint, payload)
  }

  updateEventCenterBlock(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventBlockEndpoint}/${id}`, payload)
  }

  deleteEventCenterBlock(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventBlockEndpoint}/${id}`)
  }

  fetchEventCenterBlocks(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminEventBlockEndpoint, {
      params
    })
  }

  exportEventBlocks(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventBlockEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Event Center Accommodation */
  createEventCenterAccommodation(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminEventAccommodationEndpoint, payload)
  }

  updateEventCenterAccommodation(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventAccommodationEndpoint}/${id}`, payload)
  }

  deleteEventCenterAccommodation(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventAccommodationEndpoint}/${id}`)
  }

  fetchEventCenterAccommodations(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminEventAccommodationEndpoint, {
      params
    })
  }

  exportEventCenterAccommodations(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventAccommodationEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Event Caterers */
  createCaterer(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminCatererEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  updateCaterer(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCatererEndpoint}/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  createSpeaker(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminSpeakerEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  updateSpeaker(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminSpeakerEndpoint}/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deleteSpeaker(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminSpeakerEndpoint}/${id}`)
  }

  fetchSpeakers(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminSpeakerEndpoint, {
      params
    })
  }

  exportSpeakers(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminSpeakerEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  deleteCaterer(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminCatererEndpoint}/${id}`)
  }

  fetchCaterers(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminCatererEndpoint, {
      params
    })
  }

  exportCaterers(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCatererEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Canteens */
  createCanteen(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminCanteenEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  updateCanteen(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCanteenEndpoint}/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deleteCanteen(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminCanteenEndpoint}/${id}`)
  }

  fetchCanteens(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminCanteenEndpoint, {
      params
    })
  }

  exportCanteens(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCanteenEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Products */
  createProduct(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminProductEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  fetchProduct(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminProductEndpoint}/${id}`)
  }

  updateProduct(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminProductEndpoint}/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deleteProduct(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminProductEndpoint}/${id}`)
  }

  fetchProducts(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminProductEndpoint, {
      params
    })
  }

  exportProducts(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminProductEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Product Options */
  createProductOption(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminProductOptionEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  fetchProductOption(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminProductOptionEndpoint}/${id}`)
  }

  updateProductOption(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminProductOptionEndpoint}/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deleteProductOption(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminProductOptionEndpoint}/${id}`)
  }

  fetchProductOptions(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminProductOptionEndpoint, {
      params
    })
  }

  exportProductOptions(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminProductOptionEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Admin Events */
  createNewEvent(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  fetchEvent(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventEndpoint}/${id}`, {})
  }

  fetchEventStats(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventEndpoint}/${id}/stats`, {})
  }

  fetchEventProducts(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventEndpoint}/${id}/products`, {})
  }

  updateEvent(event_id, formData) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  deleteEvent(event_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}`, {})
  }

  publishEvent(event_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/publish`, {})
  }

  unPublishEvent(event_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/unpublish`, {})
  }

  fetchEvents(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventEndpoint}`, {
      params
    })
  }

  deleteEventAccommodation(event_id, accommodation_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/accommodations/${accommodation_id}`, {})
  }

  deleteEventSpeaker(event_id, speaker_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/speekers/${speaker_id}`, {})
  }

  addFieldDraft(event_id, payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/fields`, payload)
  }

  updateField(event_id, field_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/fields/${field_id}`, payload)
  }

  deleteField(event_id, field_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/fields/${field_id}`, {})
  }

  addActivity(event_id, payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/activities`, payload)
  }

  updateActivity(event_id, field_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/activities/${field_id}`, payload)
  }

  deleteActivity(event_id, field_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/activities/${field_id}`, {})
  }

  /** Reservations */
  fetchReservations(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReservationsEndpoint}`, {
      params
    })
  }

  exportReservations(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReservationsEndpoint}/export`, {
      params
    })
  }

  fetchReservation(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReservationsEndpoint}/${id}`, {})
  }

  makeReservationPayment(id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminReservationsEndpoint}/${id}/cash-payment`, {})
  }

  printReservationDetails(id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminReservationsEndpoint}/${id}/generate-pdf`, {})
  }

  fetchReservationPayments(reservation_id, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReservationsEndpoint}/${reservation_id}/payments`, { params })
  }

  fetchPaymentsForReservations(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReservationsEndpoint}/payments`, { params })
  }

  fetchCashPayments(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReservationsEndpoint}/cash-payments`, { params })
  }
  
  makeReservationForUser(payload) {
    const { event_id, ...rest } = payload
    return this.axiosIns.post(`${this.jwtConfig.admin.adminReservationsEndpoint}/${event_id}`, rest)
  }
}
