<template>
  <b-modal
    id="add-or-update"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            {{ isUpdate ? 'Update Product Option' : 'Add New Product Option' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <div class="border rounded p-2 mb-2">
              <label class="">Thumbnail</label>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside v-if="thumbnailImagePath">
                  <b-img
                    :src="thumbnailImagePath"
                    height="120"
                    width="120"
                    rounded
                    fluid
                    class="mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>

                <b-media-body>
                  <small class="text-muted">Image max size 5mb.</small>
                  <div class="d-inline-block w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Thumbnail"
                      :rules="{
                        required: false,
                        size: 5000,
                      }"
                    >
                      <b-form-group
                        label=""
                        label-for="thumbnail-file"
                      >
                        <b-form-file
                          id="thumbnail-file"
                          v-model="thumbnailImage"
                          accept=".jpg, .png, .jpeg, .webp"
                          placeholder="Choose file"
                          :state="errors.length > 0 ? false : null"
                          @input="onAvatarRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <template #label>
                  <span>Title<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-input
                  id="title"
                  v-model="new_data.title"
                  autofocus
                  placeholder=""
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Description"
              rules=""
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="new_data.description"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                {{ isUpdate ? 'Update' : 'Add' }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormFile,
  BAlert, BCardText, BModal, BMedia, BMediaBody, BMediaAside, BImg
} from 'bootstrap-vue'

import { required } from '@validations'
import { get, cloneDeep, omit } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawData = {
  title: "",
  description: ""
}

export default {
  components: {
    BImg,
    BForm,
    BAlert,
    BModal,
    BMedia, 
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormFile,
    BFormGroup,
    BMediaBody, 
    BFormInput,
    BMediaAside,
    BFormTextarea,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      default: null,
      required: false
    },
    productId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      new_data: cloneDeep(rawData),
      loading: false,
      errorMessage: "",

      thumbnailImage: null,
      thumbnailImagePath: "",

      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Delete', value: 'deleted' },
      ],
      required,
    }
  },
  computed: {
    isUpdate(){
      return !!this.updateData
    },
    disableForm(){
      if (this.isUpdate){
        return !this.can("product_options:CanUpdate")
      }

      return !this.can("product_options:CanAddNew")
    },
  },
  watch: {
    updateData: {
      handler(updateData){
        if (updateData){
          this.new_data = {
            _id: updateData._id,
            title: updateData.title,
            description: updateData.description
          }
          this.thumbnailImagePath = this.getValueFromSource(updateData, 'thumbnail_image.path');
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const messages = {
          update: {
            title: "Confirm Product Option Update",
            text: "You are about to update details of a product option.",
            icon: "warning"
          },
          new: {
            title: "Create Product Option",
            text: "You are about to create a product option",
            icon: "info"
          }
        }

        /* eslint-disable no-nested-ternary */
        const message = this.isUpdate ? messages.update : messages.new;
        const { title, text, icon } = message;

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }

        const formData = new FormData();
        formData.append('product_id', this.productId)

        if (this.thumbnailImage){
          formData.append('thumbnail_image', this.thumbnailImage)
        }
        
        if (this.isUpdate){
          const id = this.new_data._id
          const update_data = omit(this.new_data, "_id");

          Object.keys(update_data).forEach(key => {
            const value = update_data[key]
            if (Array.isArray(value)){
              value.forEach(val => {
                formData.append(key, val);
              })
            } else {
              formData.append(key, value);
            }
          })

          if (!this.can("product_options:CanUpdate")) {
            return
          }

          await this.useJwt().adminService.updateProductOption(id, formData);
        } else {
          if (!this.can("product_options:CanAddNew")) {
            return
          }

          Object.keys(this.new_data).forEach(key => {
            const value = this.new_data[key]
            if (Array.isArray(value)){
              value.forEach(val => {
                formData.append(key, val);
              })
            } else {
              formData.append(key, value);
            }
          })

          await this.useJwt().adminService.createProductOption(formData);
        }
        this.$emit("refetchData");
        this.$emit('update:is-add-or-update-active', false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Canteen ${this.isUpdate ? 'updated' : 'added'} successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onAvatarRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.thumbnailImagePath = reader.result
          this.thumbnailImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      this.thumbnailImage = null;
      this.thumbnailImagePath = '';
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
