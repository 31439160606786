<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="userData === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching User details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-users'}"
        >
          Users
        </b-link>
      </div>
    </b-alert>

    <template v-if="userData">
      <validation-observer ref="newUserForm" #default="{invalid}">
        <b-form ref="form" @submit.prevent="onUpdateUser">
          <b-row>
            <b-col
              cols="12"
              xl="8"
              md="8"
              >
              <div>
                <b-card>
                  <b-row class="">
                    <b-col
                      cols="11"
                    >
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="UserIcon"
                          size="19"
                        />
                        <h4 class="mb-0 ml-50">
                          Personal Information
                        </h4>
                      </div>
                    </b-col>

                    <b-col
                      cols="1"
                    >
                      <div v-if="canDeleteMFA && userData.mfa_conf"
                          class="justify-content-end"
                      >
                        <b-dropdown variant="link" class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                          <template #button-content class="p-0">
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-top text-body" />
                          </template>
                          <b-dropdown-item @click="onResetUserMFA">
                            <feather-icon class="text-danger" icon="EditIcon" />
                            <span class="align-middle text-danger ml-50">Reset MFA</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- Media -->
                  <b-media class="mt-3 mb-3">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        :src="avatarPath"
                        :text="avatarText(`${userData.first_name} ${userData.last_name}`)"
                        :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(userData, 'status')))}`"
                        size="6rem"
                      />
                    </template>

                    <h4 class="mb-1">
                      Avatar
                    </h4>
                    <div v-if="can('users:CanUpdate')" class="d-flex flex-wrap">
                      <b-button
                        variant="primary"
                        :disabled="!canUpdate"
                        @click="$refs.avatarElement.$el.childNodes[0].click()"
                      >
                        <b-form-file
                          v-show="false"
                          ref="avatarElement"
                          accept="image/*"
                          placeholder=""
                          no-drop
                          @input="onImageRenderer"
                        />
                        <span class="d-none d-sm-inline">Change Avatar</span>
                        <feather-icon
                          icon="EditIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>

                      <b-button
                        v-if="getValueFromSource(userData, 'avatar.path')"
                        :disabled="!canUpdate"
                        variant="outline-secondary"
                        class="ml-1"
                        @click="onRemoveAvatar"
                      >
                        <span class="d-none d-sm-inline">Remove</span>
                        <feather-icon
                          icon="TrashIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                    </div>
                  </b-media>

                  <b-row class="">
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="First Name"
                        label-for="first_name"
                      >
                        <b-form-input
                          id="first_name"
                          v-model="userData.first_name"
                          :disabled="!canUpdate"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Last Name"
                        label-for="last_name"
                      >
                        <b-form-input
                          id="last_name"
                          v-model="userData.last_name"
                          :disabled="!canUpdate"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="">
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="userData.email"
                          type="email"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Phone"
                        label-for="phone"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="phone"
                            v-model="userData.phone"
                            :disabled="!canUpdate"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="6">
                      <b-form-group label="User Status" label-for="status">
                        <validation-provider
                          #default="{ errors }"
                          name="User Status"
                          rules="required"
                        >
                          <v-select
                            v-model="userData.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            :reduce="(val) => val.value"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Sector"
                        label-for="sector"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Sector"
                          rules="required"
                        >
                        <v-select
                          v-model="userData.sector"
                          class="filter-select"
                          placeholder="Sector"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="sectorOptions"
                          :reduce="val => val.value"
                          :disabled="loading"
                        />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Applicant Status"
                        label-for="applicant-status"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Applicant Status"
                          rules="required"
                        >
                        <v-select
                          v-model="userData.applicant_status"
                          class="filter-select"
                          placeholder="Sector"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="applicantStatusOptions"
                          :reduce="val => val.value"
                          :disabled="loading"
                        />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Gender" label-for="gender">
                        <template #label>
                          <span>Gender<span style="color: tomato">*</span></span>
                        </template>

                        <validation-provider #default="{ errors }" name="gender" rules="required">
                          <v-select
                            id="gender"
                            v-model="userData.gender"
                            :loading="loading"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="genderOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Country" label-for="country">
                        <template #label>
                          <span>Country<span style="color: tomato">*</span></span>
                        </template>

                        <validation-provider #default="{ errors }" name="country" rules="required">
                          <v-select
                            id="country"
                            v-model="userData.country"
                            :loading="loading"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="countryOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div v-if="userData.country === 'Ghana'">
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Region" label-for="region">
                          <template #label>
                            <span>Region<span style="color: tomato">*</span></span>
                          </template>
            
                          <validation-provider #default="{ errors }" name="region" rules="required">
                            <v-select
                              id="region"
                              v-model="userData.region"
                              :loading="loading"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="regionOptions"
                              :reduce="val => val.value"
                              :clearable="false"
                              @change="fetchChurchAreas"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Area" label-for="area">
                          <template #label>
                            <span>Area<span style="color: tomato">*</span></span>
                          </template>
            
                          <validation-provider #default="{ errors }" name="area" rules="required">
                            <v-select
                              id="area"
                              v-model="userData.area"
                              :loading="loading"             
                              :clearable="false"
                              :options="areaOptions"
                              :reduce="val => val.value"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                      <b-form-group label="District" label-for="region">
                        <template #label>
                          <span>District<span style="color: tomato">*</span></span>
                        </template>
          
                        <validation-provider #default="{ errors }" name="district" rules="required">
                          <v-select
                            id="district"
                            v-model="userData.district"
                            :loading="loading"             
                            :clearable="false"
                            :options="districtOptions"
                            :reduce="val => val.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                      <b-form-group label="Assembly" label-for="assembly">
                      <template #label>
                        <span>Assembly<span style="color: tomato">*</span></span>
                      </template>
        
                      <validation-provider #default="{ errors }" name="assembly" rules="required">
                        <v-select
                          id="assembly"
                          v-model="userData.assembly"
                          :loading="loading"             
                          :clearable="false"
                          :options="assemblyOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  
                   <div v-else>
                     <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Region" label-for="region">
                          <template #label>
                            <span>Region<span style="color: tomato">*</span></span>
                          </template>

                          <validation-provider #default="{ errors }" name="region" rules="required">
                            <input id="region" v-model="userData.region" placeholder="Enter region name here" class="form-control">
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Area" label-for="area">
                          <template #label>
                            <span>Area<span style="color: tomato">*</span></span>
                          </template>

                          <validation-provider #default="{ errors }" name="area" rules="required">
                            <input id="area" v-model="userData.area" placeholder="Enter area name here" class="form-control">
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="District" label-for="district">
                          <template #label>
                            <span>District<span style="color: tomato">*</span></span>
                          </template>

                          <validation-provider #default="{ errors }" name="district" rules="required">
                            <input id="district" v-model="userData.district" placeholder="Enter district name here" class="form-control">
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group label="Assembly" label-for="assembly">
                          <template #label>
                            <span>Assembly<span style="color: tomato">*</span></span>
                          </template>
  
                          <validation-provider #default="{ errors }" name="assembly" rules="required">
                            <input id="assembly" v-model="userData.assembly" placeholder="Enter assembly name here" class="form-control">
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                   </div>


                </b-card>

                <b-card 
                  v-if="[
                    getValueFromSource(userData, 'user_type')
                  ].includes(true)"
                  class="mt-2"
                >
                  <div class="d-flex">
                    <feather-icon
                      icon="ArchiveIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50">
                      Primary Information
                    </h4>
                  </div>
                </b-card>

                 <b-button
                  class="mb-5"
                  style="width: 200px"
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || disableForm || loading"
                >
                  Update {{ capitalizeFirstWord(getValueFromSource(userData, 'user_type')) }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          
          <pre>
            {{ userData }}
          </pre>
        </b-form>
      </validation-observer>
    </template>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BLink, BRow, BCol,
  BCard, BCardBody, BButton, BFormGroup, 
  BForm, BFormInput, BMedia, BAvatar,
  BFormFile, BFormDatepicker, BBadge,
  BInputGroup, BInputGroupPrepend, BDropdown,
  BDropdownItem, BFormSelect
} from "bootstrap-vue"
import Cleave from 'vue-cleave-component'

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import { get } from "lodash";
import { required, email } from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import cloneDeep from "lodash/cloneDeep";


export default {
  components: {
    Cleave,
    vSelect,
    flatPickr,
    BOverlay,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCard, 
    BCardBody, 
    BButton,
    BFormGroup, 
    BForm, 
    BFormInput,
    BMedia, 
    BAvatar,
    BFormFile,
    BFormDatepicker,
    BBadge,
    BInputGroup,
    BDropdown,
    BFormSelect,
    BDropdownItem,
    BInputGroupPrepend,
    
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {},
  data(){
    return {
      loading: false,

      userData: null,
      originalUser: null,
      permissionOptions: [],

      avatarPath: "",
      updatedImage: null,
      cleaveDateOptions: {
        date: true,
        delimiter: '/',
        datePattern: ['Y', 'm', 'd'],
        blocks: [4, 2, 2],
        delimiters: ['-', '-', '-']
      },
      required,
      email,
      applicantStatusOptions: [
        { label: "Student", value: 'Student' },
        { label: "Alumni", value: 'Alumni' },
      ],
      genderOptions: [
        {
          value: "female",
          label: "Female"
        },
        {
          value: "male",
          label: "Male"
        }
      ],
      sectorOptions: [
        "Not applicable",
        "Madina Sector",
        "Kaneshie Sector",
        "Upper Volta Sector",
        "Lower Volta Sector", 
        "Mankessim Sector",
        "Sunyani Sector",
        "Cape Coast Sector",
        "Techiman Sector",
        "Takoradi Sector",
        "Tarkwa Sector",
        "Koforidua Sector",
        "Suhum Sector",
        "Bompata Sector",
        "Kwadaso Sector",
        "Mampong Sector", 
        "Northern Sector",
        "Upper East Sector", 
        "Upper West sector",
        "KNUST CAMPUS",
        "UCC CAMPUS",
        "UEW CAMPUS",
        "UG CAMPUS",
        "PU CAMPUS",
      ].map(title => ({
        label: title,
        value: title
      })),
      statusOptions: [
        { label: "Active", value: "active" },
        { label: "In-Active", value: "inactive" },
        { label: "Banned", value: "banned" },
        { label: "Blocked", value: "blocked" },
        { label: "Pending Email Verification", value: "pending_confirmation" }
      ],

      regionOptions: [],
      areaOptions: [],
      districtOptions: [],
      assemblyOptions: [],
      countryOptions: [],
    }
  },
  computed: {
    canUpdateClientBasic(){
      return this.can('users:CanUpdateBasic')
    },
    canUpdateClientPrimary(){
      return this.can("users:CanUpdatePrimary")
    },
    canUpdateClientAdditionalInformation(){
      return this.can("users:CanUpdateAdditionalInfo");
    },
    canUpdate(){
      return this.can('users:CanUpdate');
    },
    disableForm(){
      return !this.can('users:CanUpdate');
    },
    canDeleteMFA() {
      return this.can("mfa_configuration:CanDelete");
    },
  },
  watch: {
    "userData.country": {
      handler(changed){
        if (changed !== this.originalUser.country){
          this.userData.region = ""
          this.userData.area = ""
          this.userData.district = ""
          this.userData.assembly = ""
        }
      },
      immediate: false
    },
    "userData.region": {
      handler(changed){
        console.log("REGION CHANGED", changed, this.userData.country)
        if (changed){
          if (this.userData.country !== "Ghana") { return }
          this.fetchChurchAreas();
        }
      },
      immediate: false
    },
    "userData.area": {
      handler(changed){
        if (changed){
          if (this.userData.country !== "Ghana") { return }
          this.fetchChurchDistrict();
        }
      },
      immediate: false
    },
    "userData.district": {
      handler(changed){
        if (changed){
          if (this.userData.country !== "Ghana") { return }
          this.fetchChurchAssemblies();
        }
      },
      immediate: false
    }
  },
  created(){
    this.fetchUserDetails();
    this.fetchCountries()
    this.fetchChurchRegions();
  },
  methods: {
    async fetchUserDetails(){
      try {
        this.loading = true;
        const { id } = this.$route.params;
        
        const response = await this.useJwt().adminService.fetchUser(id);
        const userData = get(response, 'data.data');
        
        this.userData = {
          country: 'Ghana',
          region: '',
          area: '',
          district: '',
          assembly: '',
          ...cloneDeep(userData)
        }
        this.originalUser = cloneDeep(userData)
        this.avatarPath = this.getValueFromSource(this.userData, 'avatar.path', '');
      } catch (error){
        this.userData = undefined;

        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onUpdateUser(){
      try {
        this.loading = true;

        const formData = new FormData();
        if (this.canUpdate){
          formData.append('first_name', this.getValueFromSource(this.userData, 'first_name', ''));
          formData.append('last_name', this.getValueFromSource(this.userData, 'last_name', ''));
          formData.append('status', this.getValueFromSource(this.userData, 'status', ''));
          formData.append('phone', this.getValueFromSource(this.userData, 'phone', ''));
          formData.append('sector', this.getValueFromSource(this.userData, 'sector', ''));
          formData.append('applicant_status', this.getValueFromSource(this.userData, 'applicant_status', ''));

          formData.append('country', this.getValueFromSource(this.userData, 'country', ''));
          formData.append('region', this.getValueFromSource(this.userData, 'region', ''));
          formData.append('area', this.getValueFromSource(this.userData, 'area', ''));
          formData.append('district', this.getValueFromSource(this.userData, 'district', ''));
          formData.append('assembly', this.getValueFromSource(this.userData, 'assembly', ''));
          formData.append('gender', this.getValueFromSource(this.userData, 'gender', ''));
        }

        if (this.updatedImage){
          formData.append("avatar", this.updatedImage);
        }

        if (!this.canUpdate){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: "An unexpected error occurred. You might not have permission to update record."
            },
          });
          return false;
        }

        const user_id = this.userData._id;
        await this.useJwt().adminService.updateUser(user_id, formData);
        await this.fetchUserDetails();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'EditIcon',
            variant: 'success',
            text: `User updated successfully.`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onRemoveAvatar(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Avatar Removal?',
          text: "Are you sure you want to remove this avatar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!result.value) {
          return;
        }

        const user_id = this.userData._id;
        if (!user_id){
          return;
        }

        await this.useJwt().adminService.deleteUserAvatar(user_id);
        this.avatarPath = "";
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchRegions();
        const { data } = request.data;
        this.regionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAreas(this.userData.region);
        const { data } = request.data;
        this.areaOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        const currentAreaIsValid = this.areaOptions.some(area => area.value === this.userData.area)

        if (!currentAreaIsValid) {
          this.userData.area = '';
          this.userData.district = '';
          this.userData.assembly = '';
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchDistricts(this.userData.region, this.userData.area);
        const { data } = request.data;
        this.districtOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        const currentDistrictIsValid = data.some(district => district.name === this.userData.district)

        if (!currentDistrictIsValid) {
          this.userData.district = '';
          this.userData.assembly = '';
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const { area, district, region } = this.userData

        const request = await this.useJwt().sharedService.fetchChurchAssemblies(region, area, district);
        const { data } = request.data;
        this.assemblyOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        const isValid = this.assemblyOptions.some(assembly => assembly.value === this.userData.assembly)
        if (!isValid) {
          this.userData.assembly = '';
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchCountries() {
      try {
        const response = await this.useJwt().sharedService.fetchCountries()

        const countries = get(response, 'data.data')
        this.countryOptions = countries.map(country => ({
          label: country.country,
          value: country.country
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.avatarPath = reader.result
          this.updatedImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    async onResetUserMFA() {
      try {
        this.loading = true;
        const result = await this.$swal({
          title: 'Reset User MFA Config',
          text: "Are you sure you want to reset user mfa configuration?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.value || !this.userData._id) {
          return;
        }
        await this.useJwt().adminService.resetUserMFA(this.userData._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "MFA on Admin User account has been reset successfully."
          }
        });
        await this.$router.push({
          name: "admin-users"
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
