<template>
  <b-modal
    id="add-or-update-event-block"
    :visible="showEventReservationModal"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:show-event-reservation-modal', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Make Event Reservation
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateReservation)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <validation-provider
              #default="validationContext"
              name="Event"
              rules="required"
            >
              <b-form-group
                label="Event"
                label-for="event"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.event_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="event"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="requiresAccommodation"
              #default="validationContext"
              name="Accommodation"
              rules="required"
            >
              <b-form-group
                label="Accommodation"
                label-for="event"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.accommodation_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventAccommodationOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="event"
                  :disabled="!new_data.event_id"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="requiresCanteen"
              #default="validationContext"
              name="Canteen"
              rules="required"
            >
              <b-form-group
                label="Canteen"
                label-for="event_center"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.canteen_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventCanteenOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="event_center"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="requiresProduct"
              #default="validationContext"
              name="Products"
              rules="required"
            >
              <b-form-group
                label="Products"
                label-for="products"
                autofocus
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="new_data.products"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventProductOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :multiple="true"
                  input-id="products"
                  :disabled="!new_data.event_id"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Cash Payment Received?"
              rules="required"
            >
              <b-form-group
                label="Cash Payment Received?"
                label-for="cash_payment_received"
              >
                <b-form-checkbox
                  id="cash_payment_received"
                  v-model="new_data.cash_payment_received"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <h1 v-if="totalCost">
              Cost: GHS {{ formatMoney(totalCost) }}
            </h1>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                Make Reservation
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton,
  BAlert, BCardText, BModal, BFormCheckbox
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required, min_value, max_value, numeric } from '@validations'
import { get, cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

const rawEventCenterAccommodationData = {
  cash_payment_received: false,
  event_id: "",
  accommodation_id: "",
  canteen_id: "",
  user_id: "",
  products: []
}

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BAlert,
    BCardText,
    vSelect,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showEventReservationModal',
    event: 'update:show-event-reservation-modal',
  },
  props: {
    showEventReservationModal: {
      type: Boolean,
      required: true,
    },
    userId: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      numeric,
      required,
      min_value,
      max_value,
      eventCenters: [],
      events: [],
      eventAccommodations: [],
      eventProducts: [],

      loading: false,
      errorMessage: "",
      event_center_blocks: [],
      new_data: cloneDeep(rawEventCenterAccommodationData),
    }
  },
  computed: {
    disableForm() {
      return !this.can('reservations:CanAddNew')
    },
    selectedAccommodationId() {
      return this.new_data.accommodation_id
    },
    accommodationPrice() {
      if (!this.selectedAccommodation || !this.selectedEvent) { return 0 }
      return this.selectedAccommodation.price
    },
    productsPrice() {
      if (!this.selectedEvent) { return 0 }
      const selectedProducts = this.eventProducts.filter(p => this.new_data.products.includes(p.product_id));
      return selectedProducts.reduce((prev, curr) => prev + curr.price, 0)
    },
    totalCost() {
      return this.accommodationPrice + this.productsPrice
    },
    selectedAccommodation() {
      return this.selectedEvent && this.selectedEvent.accommodations_data.find(accm => accm._id === this.selectedAccommodationId)
    },
    selectedEventId() {
      return this.new_data.event_id
    },
    selectedEvent() {
      return this.events.find(event => event._id === this.new_data.event_id)
    },
    requiresAccommodation() {
      if (this.selectedEvent) {
        return this.selectedEvent.require_accommodation
      }
      return true;
    },
    requiresProduct() {
      if (this.selectedEvent) {
        return this.selectedEvent.require_product
      }
      return true;
    },
    requiresCanteen() {
      if (this.selectedEvent) {
        return this.selectedEvent.require_canteen
      }
      return true;
    },
    eventOptions() {
      return this.events.map(event => ({ label: event.title, value: event._id }))
    },
    eventAccommodationOptions() {
      if (!this.selectedEventId) { return [] }
      const event = this.events.find(e => e._id === this.selectedEventId)
      if (event) {
        return event.accommodations_data.map(accm => ({ label: accm.title, value: accm._id }))
      }
      return []
    },
    eventCanteenOptions() {
      if (!this.selectedEventId) { return [] }
      const event = this.events.find(e => e._id === this.selectedEventId)
      if (event) {
        return event.canteens_data.map(accm => ({ label: accm.title, value: accm._id }))
      }
      return []
    },
    eventProductOptions() {
      if (!this.selectedEventId) { return [] }
      const event = this.events.find(e => e._id === this.selectedEventId)
      if (event) {
        return this.eventProducts.map(accm => ({ label: `${accm.name} - GHS${this.formatMoney(accm.price)}`, value: accm.product_id }))
      }
      return []
    },
  },
  watch: {
    'new_data.event_id': {
      handler(event_id) {
        event_id && this.updateEventProducts(event_id)
      }
    }
  },
  created() {
    this.fetchUpcomingEvents()
  },
  methods: {
    async onCreateReservation(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const title = "Make event reservation for user"
        const text = "You are about to make an event reservation for a user"
        const icon = "info"

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }

        const payload = { ...this.new_data, user_id: this.userId }
        if (!payload.canteen_id) {
          delete payload.canteen_id
        }

        if (!payload.accommodation_id) {
          delete payload.accommodation_id
        }
        
        const response = await this.useJwt().adminService.makeReservationForUser(payload);

        this.$emit('update:show-event-reservation-modal', false);
        this.$router.push({ name: 'admin-single-reservations', params: { id: this.getValueFromSource(response, 'data.data._id') } })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Event reservation successfull`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchUpcomingEvents() {
      try {
        this.loading = true;

        const request = await this.useJwt().adminService.fetchEvents({
          populate: [
            "accommodations_data",
            "canteens_data",
            "products_data"
          ]
        });
        const { data } = request.data;

        this.events = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async updateEventProducts(event_id) {
      try {
        this.loading = true;

        const response = await this.useJwt().adminService.fetchEventProducts(event_id);
        const { data } = response.data;

        this.eventProducts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update-event-block {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
