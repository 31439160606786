<template>
  <b-row v-if="items.length > 0" class="blog-list-wrapper">
    <b-col v-for="(content, i) in items" :key="i" md="6">
      <b-card tag="article" no-body>
        <b-card-body>
          <b-card-title>
            <div class="d-flex justify-content-between align-items-center">
              <b-link
                :to="{ name: 'admin-single-blog', params: { id: getValueFromSource(content, '_id') } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ getValueFromSource(content, 'title') }}
              </b-link>
              <b-badge
                pill
                :variant="getValueFromSource(content, 'status') === 'draft' ? 'light-primary' : 'light-success'"
                class="text-capitalize status-badge"
              >
                {{ capitalizeFirstWord(getValueFromSource(content, 'status')) }}
              </b-badge>
            </div>
          </b-card-title>

          <b-card-text class="blog-content-truncate" style="color: #888B97; font-weight: 400; font-size: 12px; line-height: 160%;">
            {{ extractTextContentFromHTML(getValueFromSource(content, 'content')) }}
          </b-card-text>
          <!-- <hr> -->
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <b-avatar
                size="24"
                :src="getValueFromSource(content, 'owner.avatar.path')"
                style="margin-right: 5px;"
              />
              <span style="color: #B6C8D1; font-weight: 400; font-size: 10px; line-height: 15px;">
                <span>{{ sentenceCase(`${getValueFromSource(content, 'owner.first_name')}`) }}</span>
                <span>{{ formatDate(content.modified) }}</span>
              </span>
            </div>
            <div>
              <feather-icon
                icon="EditIcon"
                size="20"
                class="cursor-pointer"
                style="color: #B6C8D1; margin-right: 7px;"
                @click="$router.push({ name: detailPage, params: { id: getValueFromSource(content, '_id') } })"
              />

              <feather-icon
                icon="Trash2Icon"
                size="20"
                class="cursor-pointer"
                style="color: #D10101"
                @click="deleteCard(content._id)"
              />
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="12">
      <!-- pagination -->
      <div class="my-2">
        <b-pagination :value="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" @change="$emit('update:currentPage', $event)">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BBadge,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";

export default {
  name: "BlogCard",
  components: {
    BCard,
    BRow,
    BOverlay,
    BLink,
    BCol,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormInput,
    BButton,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody
  },
  props: {
    currentPage: { type: Number, required: true },
    totalRecords: { type: Number, required: true },
    perPage: { type: Number, required: true },
    detailPage: { type: String, required: true },
    showBadge: { type: Boolean, default: false },
    items: { type: Array, required: true },
    deleteTitle: { type: String, default: "Delete card" },
    deleteMessage: { type: String, default: "You are about to delete this card. Continue?" },
  },
  methods: {
    async deleteCard(item_id) {
      const deleteConfirmation = await this.$swal({
        title: this.deleteTitle,
        text: this.deleteMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (!deleteConfirmation.value){
        return;
      }

      this.$emit("delete-item", item_id);
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
